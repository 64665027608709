import { fetchConToken, fetchConTokenFiltro, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearCalificacionAsignatura = ( calificacionAsignatura ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('libroDigital/crearAsignaturaCalificacion', calificacionAsignatura, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(crearCalificacionAsignaturaOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCalificacionAsignaturaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCalificacionAsignaturaError());
        }
    }
}

const crearCalificacionAsignaturaOk = (e) => ({
    type: types.crearCalificacionAsignaturaOk,
    payload: e
});

const crearCalificacionAsignaturaError = () => ({ type: types.crearCalificacionAsignaturaError });


export const obtenerCalificacionesAsignatura = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerCalificacionesAsignatura', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCalificacionesAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCalificacionesAsignaturaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCalificacionesAsignaturaError());
        }
    }
}
export const obtenerCalificacionesAsignaturaPeriodo = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerCalificacionesAsignaturasCurso', filtro);
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCalificacionesAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCalificacionesAsignaturaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCalificacionesAsignaturaError());
        }
    }
}

const obtenerCalificacionesAsignaturaOk = (e) => ({ 
    type: types.obtenerCalificacionesAsignaturaOk ,
    payload: e
});

const obtenerCalificacionesAsignaturaError = () => ({ type: types.obtenerCalificacionesAsignaturaError });



export const actualizarCalificacionAsignatura = (calificacionAsignatura) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarCalificacionAsignatura/${calificacionAsignatura._id}`, calificacionAsignatura, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionAsignaturaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarCalificacionAsignaturaError())
        }
    }
}

const actualizarCalificacionAsignaturaOk = (e) => ({ 
    type: types.actualizarCalificacionAsignaturaOk,
    payload: e
});

const actualizarCalificacionAsignaturaError = () => ({ type : types.actualizarCalificacionAsignaturaError });

export const activarCalificacionAsignatura = (e) => ({ 
    type: types.activarCalificacionAsignatura,
    payload: e
});

export const limpiarCalificacionsCalificacionAsignatura = () => ({ 
    type: types.limpiarCalificacionsAsignatura
});
