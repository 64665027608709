import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearAsignatura = ( asignatura ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearAsignatura', asignatura, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearAsignaturaOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearAsignaturaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearAsignaturaError());
        }
    }
}

const crearAsignaturaOk = (e) => ({
    type: types.crearAsignaturaOk,
    payload: e
});

const crearAsignaturaError = () => ({ type: types.crearAsignaturaError });


export const obtenerAsignaturas = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerAsignaturas');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsignaturasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsignaturasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsignaturasError());
        }
    }
}

export const obtenerAsignaturasCurso = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsignaturasCurso', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsignaturasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsignaturasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsignaturasError());
        }
    }
}
export const obtenerAsignaturasCursoPeriodoEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsignaturasCursoPeriodoEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsignaturasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsignaturasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsignaturasError());
        }
    }
}

export const obtenerAsignaturasAnioEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsignaturasAnioEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsignaturasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsignaturasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsignaturasError());
        }
    }
}

const obtenerAsignaturasOk = (e) => ({ 
    type: types.obtenerAsignaturasOk ,
    payload: e
});

const obtenerAsignaturasError = () => ({ type: types.obtenerAsignaturasError });



export const actualizarAsignatura = (asignatura) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarAsignatura/${asignatura._id}`, asignatura, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAsignaturaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarAsignaturaError())
        }
    }
}

const actualizarAsignaturaOk = (e) => ({ 
    type: types.actualizarAsignaturaOk,
    payload: e
});

const actualizarAsignaturaError = () => ({ type : types.actualizarAsignaturaError });

export const activarAsignatura = (e) => ({ 
    type: types.activarAsignatura,
    payload: e
});

export const limpiarAsignaturas = () => ({ 
    type: types.limpiarAsignaturas
});
