import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import { crearClaseTaller } from '../../../../action/clasesTaller';
import { libroDigitalTablaClasesTaller } from '../../../../action/router';
import { useForm } from '../../../../hooks/useForm';

export const FormClaseTallerNueva = ({tallerActivo}) => {
    const dispatch = useDispatch();
    const [claseTaller, handleInputChange] = useForm({
        titulo:'',
        objetivo:'',
        fecha:'',
        taller: tallerActivo,
    })

    const  { titulo, objetivo, fecha } = claseTaller;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( titulo === '' || objetivo === '' || fecha === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearClaseTaller(claseTaller));
        dispatch(libroDigitalTablaClasesTaller())
    }

    const handleCancelar = (e) => {
        e.preventDefault();
        dispatch(libroDigitalTablaClasesTaller());
    }

    return (
        <form>
            <h5>Formulario ingreso clase curso</h5>
            <div className="row mb-3 mt-3">
                <label htmlFor="titulo" className="col-2 col-form-label">Titulo</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titulo"
                        name="titulo"
                        value={ titulo }
                        onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="fecha" className="col-2 col-form-label">Fecha</label>
                <div className="col-4">
                    <input 
                        type="date" 
                        className="form-control" 
                        id="fecha"
                        name="fecha"
                        value={ fecha }
                        onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="objetivo" className="col-2 col-form-label">Objetivo</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="objetivo"
                        name="objetivo"
                        value={ objetivo }
                        onChange={ handleInputChange }
                    />
                </div>
            </div>
            
            <div className="row mb-3 mt-3">
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleEnviar }
                    >Crear</button>
                </div>
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-danger" 
                        onClick={ handleCancelar }
                    >Cancelar</button>
                </div>
            </div>
        </form>
    )
}
