import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import { activarAsignatura } from '../../../action/asignatura';
import { libroDigitalTablaCalificacionesAsignatura, libroDigitalTablaClasesAsignatura } from '../../../action/router';

export const RowAsignatura = ({asignatura, docentes, index}) => {
  const dispatch = useDispatch();
  const docentePrincipal = docentes.find(e=>e._id === asignatura.docente)

  const handleClases = () => {
    dispatch(activarAsignatura(asignatura._id))
    dispatch(libroDigitalTablaClasesAsignatura())
  }


  const handleCalificaciones = () => {
    dispatch(activarAsignatura(asignatura._id));
    dispatch(libroDigitalTablaCalificacionesAsignatura())
  }

  return (
    <tr>
      <th>{index}</th>
      <td>{asignatura.nombre}</td>
      <td>{docentePrincipal.nombres} {docentePrincipal.apellidoPaterno} {docentePrincipal.apellidoMaterno}</td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-primary btn-sm' onClick={handleClases} >Clases</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-primary btn-sm' onClick={handleCalificaciones} >Calificaciones</button>
        </div>
      </td>
    </tr>
  )
}
