import { fetchConToken, fetchConTokenFiltro, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearAsistencia = ( asistencia ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('libroDigital/crearAsistencia', asistencia, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearAsistenciaOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearAsistenciaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearAsistenciaError());
        }
    }
}

const crearAsistenciaOk = (e) => ({
    type: types.crearAsistenciaOk,
    payload: e
});

const crearAsistenciaError = () => ({ type: types.crearAsistenciaError });


export const obtenerAsistencias = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerAsistencias');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsistenciasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsistenciasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsistenciasError());
        }
    }
}

export const obtenerAsistenciasCurso = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsistenciasCurso', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsistenciasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsistenciasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsistenciasError());
        }
    }
}

export const obtenerAsistenciasAnioEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsistenciasAnioEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsistenciasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsistenciasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsistenciasError());
        }
    }
}

const obtenerAsistenciasOk = (e) => ({ 
    type: types.obtenerAsistenciasOk ,
    payload: e
});

const obtenerAsistenciasError = () => ({ type: types.obtenerAsistenciasError });



export const actualizarAsistencia = (asistencia) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarAsistencia/${asistencia._id}`, asistencia, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAsistenciaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAsistenciaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarAsistenciaError())
        }
    }
}

const actualizarAsistenciaOk = (e) => ({ 
    type: types.actualizarAsistenciaOk,
    payload: e
});

const actualizarAsistenciaError = () => ({ type : types.actualizarAsistenciaError });

export const activarAsistencia = (e) => ({ 
    type: types.activarAsistencia,
    payload: e
});

export const limpiarAsistencias = () => ({ 
    type: types.limpiarAsistencias
});
