import { fetchConToken, fetchConTokenFiltro, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearAnotacion = ( anotacion ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('libroDigital/crearAnotacion', anotacion, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearAnotacionOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearAnotacionError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearAnotacionError());
        }
    }
}

const crearAnotacionOk = (e) => ({
    type: types.crearAnotacionOk,
    payload: e
});

const crearAnotacionError = () => ({ type: types.crearAnotacionError });


export const obtenerAnotaciones = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerAnotaciones', filtro);
            const body = await resp.json();
            console.log('anotaciones ----->', body)
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAnotacionesOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAnotacionesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAnotacionesError());
        }
    }
}

const obtenerAnotacionesOk = (e) => ({ 
    type: types.obtenerAnotacionesOk ,
    payload: e
});

const obtenerAnotacionesError = () => ({ type: types.obtenerAnotacionesError });



export const actualizarAnotacion = (anotacion) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`libroDigital/actualizarAnotacion/${anotacion._id}`, anotacion, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAnotacionOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAnotacionError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarAnotacionError())
        }
    }
}

const actualizarAnotacionOk = (e) => ({ 
    type: types.actualizarAnotacionOk,
    payload: e
});

const actualizarAnotacionError = () => ({ type : types.actualizarAnotacionError });

export const activarAnotacion = (e) => ({ 
    type: types.activarAnotacion,
    payload: e
});

export const limpiarAnotaciones = () => ({ 
    type: types.limpiarAnotaciones
});
