import React from 'react'
import { useDispatch } from 'react-redux'
import { actualizarAsistenciaEstudiante } from '../../../../action/asistenciaEstudiante';
import { useForm } from '../../../../hooks/useForm';

export const FormAsistenciaEstudianteObservacionActualizar = ({asistenciaEstudianteObservacion}) => {
    const dispatch = useDispatch();
    const [asistenciaEstudiante, handleInputChange] = useForm(asistenciaEstudianteObservacion);
    const {observacion} = asistenciaEstudiante;

    const handleActualizar = (e) => {
        e.preventDefault();
        dispatch(actualizarAsistenciaEstudiante(asistenciaEstudiante))
    }
    return (
        <form>
            <div className="row">
                <div className="col-12">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="observacion"
                        name="observacion"
                        value={ observacion }
                        onChange={ handleInputChange }
                        onBlur={ handleActualizar }
                    />
                </div>
            </div>
        </form>
    )
}
