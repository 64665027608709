import React from 'react'
import { FormAsistenciaNueva } from './registro/FormAsistenciaNueva'
import { TablaAsistencias } from './registro/TablaAsistencias'
import { TablaAsistenciaEstudiantes } from './registro/TablaAsistenciaEstudiantes'
import { useSelector } from 'react-redux'

export const AsistenciaRegistroScreen = () => {
  const { cursoActivo, asistencias, asistenciaActiva,asistenciaEstudiantes } = useSelector(state => state.libroDigital)
  const { docentes } = useSelector(state=>state.usuario)
  const {estudiantes}= useSelector(state=>state.estudiante)
  const { libroDigitalAsistenciaRegistroScreen, libroDigitalTablaAsistenciaEstudiantes, libroDigitalTablaAsistencias } = useSelector(state => state.router)
  return (
    <div className='row'>
      {libroDigitalTablaAsistencias && 
        <div className='col-12'>
          <FormAsistenciaNueva
            cursoActivo={cursoActivo}
          />
        </div>
      }
      {libroDigitalTablaAsistencias &&
        <div className='col-12'>
          <TablaAsistencias
            cursoActivo={cursoActivo}
            docentes={docentes}
            asistencias={asistencias}
          />
        </div>
      }
      {libroDigitalTablaAsistenciaEstudiantes &&
        <div className='col-12'>
          <TablaAsistenciaEstudiantes
            cursoActivo={cursoActivo}
            docentes={docentes}
            asistencias={asistencias}
            asistenciaActiva={asistenciaActiva}
            estudiantes={estudiantes}
            asistenciaEstudiantes={asistenciaEstudiantes}
          />
        </div>

      }
    </div>
  )
}
