import { fetchConToken, fetchConTokenFiltro, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearCalificacionTaller = ( calificacionTaller ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('libroDigital/crearTallerCalificacion', calificacionTaller, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(crearCalificacionTallerOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCalificacionTallerError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCalificacionTallerError());
        }
    }
}

const crearCalificacionTallerOk = (e) => ({
    type: types.crearCalificacionTallerOk,
    payload: e
});

const crearCalificacionTallerError = () => ({ type: types.crearCalificacionTallerError });


export const obtenerCalificacionesTaller = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerCalificacionesTaller', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCalificacionesTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCalificacionesTallerError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCalificacionesTallerError());
        }
    }
}
export const obtenerCalificacionesTallerPeriodo = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerCalificacionesTalleresCurso', filtro);
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCalificacionesTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCalificacionesTallerError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCalificacionesTallerError());
        }
    }
}

const obtenerCalificacionesTallerOk = (e) => ({ 
    type: types.obtenerCalificacionesTallerOk ,
    payload: e
});

const obtenerCalificacionesTallerError = () => ({ type: types.obtenerCalificacionesTallerError });



export const actualizarCalificacionTaller = (calificacionTaller) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarCalificacionTaller/${calificacionTaller._id}`, calificacionTaller, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionTallerError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarCalificacionTallerError())
        }
    }
}

const actualizarCalificacionTallerOk = (e) => ({ 
    type: types.actualizarCalificacionTallerOk,
    payload: e
});

const actualizarCalificacionTallerError = () => ({ type : types.actualizarCalificacionTallerError });

export const activarCalificacionTaller = (e) => ({ 
    type: types.activarCalificacionTaller,
    payload: e
});

export const limpiarCalificacionsCalificacionTaller = () => ({ 
    type: types.limpiarCalificacionsTaller
});
