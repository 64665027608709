import React from 'react'
import { useSelector } from 'react-redux'
import { DetalleCalificacionTaller } from './DetalleCalificacionTaller'
import { PromedioTaller } from './PromedioTaller'

export const TalleresEntries = ({taller, estudiante, calificacionesMayorTaller, index}) => {
    const {calificacionDetallesTaller, calificacionesTaller } = useSelector(state => state.libroDigital)
    const calificacionesTallerActual = calificacionesTaller.filter(e => e.taller === taller._id)
    const calificacionDetallesTallerActual = calificacionDetallesTaller.filter(e => e.taller === taller._id && e.estudiante === estudiante._id)
    for (let i = 0; i < calificacionesMayorTaller.length; i++) {
        if(calificacionesTallerActual.length < calificacionesMayorTaller.length){
            calificacionesTallerActual.push({_id:`id${i +1}`})
        }
    }
    return (
        <tr>
            <th>{index}</th>
            <td>{taller.nombre}</td>
            {calificacionesTallerActual && calificacionesTallerActual.map( e => (
                <DetalleCalificacionTaller 
                    key={e._id}
                    calificacion={e}
                    detallesCalificaciones={ calificacionDetallesTallerActual }
                />
            ))}
            <td>
                <PromedioTaller 
                    calificaciones = {calificacionDetallesTallerActual}                
                />
            </td>
        </tr>
    )
}
