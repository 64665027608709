import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearCurso = ( curso ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearCurso', curso, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearCursoOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCursoError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCursoError());
        }
    }
}

const crearCursoOk = (e) => ({
    type: types.crearCursoOk,
    payload: e
});

const crearCursoError = () => ({ type: types.crearCursoError });


export const obtenerCursos = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerCursos');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCursosOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCursosError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCursosError());
        }
    }
}

export const obtenerCursosEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerCursosEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCursosOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCursosError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCursosError());
        }
    }
}

export const obtenerCursosAnioEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerCursosAnioEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCursosOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCursosError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCursosError());
        }
    }
}

const obtenerCursosOk = (e) => ({ 
    type: types.obtenerCursosOk ,
    payload: e
});

const obtenerCursosError = () => ({ type: types.obtenerCursosError });



export const actualizarCurso = (curso) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarCurso/${curso._id}`, curso, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCursoOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCursoError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarCursoError())
        }
    }
}

const actualizarCursoOk = (e) => ({ 
    type: types.actualizarCursoOk,
    payload: e
});

const actualizarCursoError = () => ({ type : types.actualizarCursoError });

export const activarCurso = (e) => ({ 
    type: types.activarCurso,
    payload: e
});

export const limpiarCursos = () => ({ 
    type: types.limpiarCursos
});
