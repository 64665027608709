import React from "react";
import { settings } from "../../../../config/properties";
import { RowEstudiantes } from "./RowEstudiantes";

export const TablaAsistencias = ({estudiantes, porcentajesAsistencias}) => {
   
  return (
    <table className="table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th scope="col">{settings.numero}</th>
          <th scope="col">{settings.estudiantes}</th>
          <th scope="col">{settings.porcentajeAsistencia}</th>
          <th scope="col">{settings.informe}</th>
        </tr>
      </thead>
      <tbody>
        {estudiantes.length > 0 && estudiantes.map((estudiante,index) => (
            <RowEstudiantes 
                key={estudiante._id}
                estudiante={estudiante}
                porcentajesAsistencias={porcentajesAsistencias}
                index={index + 1}
            />
        ))}
      </tbody>
    </table>
  );
};
