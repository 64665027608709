import { fetchConToken, fetchConTokenFiltro, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearCalificacionDetalleTaller = ( calificacionDetalleTallerTaller ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('libroDigital/crearTallerCalificacionDetalle', calificacionDetalleTallerTaller, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(crearCalificacionDetalleTallerOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCalificacionDetalleTallerError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCalificacionDetalleTallerError());
        }
    }
}

const crearCalificacionDetalleTallerOk = (e) => ({
    type: types.crearCalificacionDetalleTallerOk,
    payload: e
});

const crearCalificacionDetalleTallerError = () => ({ type: types.crearCalificacionDetalleTallerError });


export const obtenerCalificacionDetallesTaller = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerCalificacionesDetallesTaller', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCalificacionesDetalleTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCalificacionesDetalleTallerError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCalificacionesDetalleTallerError());
        }
    }
}


const obtenerCalificacionesDetalleTallerOk = (e) => ({ 
    type: types.obtenerCalificacionDetallesTallerOk ,
    payload: e
});

const obtenerCalificacionesDetalleTallerError = () => ({ type: types.obtenerCalificacionDetallesTallerError });

export const actualizarCalificacionDetalleTaller = (calificacionDetalle) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`libroDigital/actualizarTallerCalificacionDetalle/${calificacionDetalle._id}`, calificacionDetalle, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionDetalleTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionDetalleTallerError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarCalificacionDetalleTallerError())
        }
    }
}

const actualizarCalificacionDetalleTallerOk = (e) => ({ 
    type: types.actualizarCalificacionDetalleTallerOk,
    payload: e
});

const actualizarCalificacionDetalleTallerError = () => ({ type : types.actualizarCalificacionDetalleTallerError });

export const activarCalificacionDetalleTaller = (e) => ({ 
    type: types.activarCalificacionDetalleTaller,
    payload: e
});

export const limpiarCalificacionDetallesTaller = () => ({ 
    type: types.limpiarCalificacionDetallesTaller
});
