import React from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { crearAsistencia } from '../../../../action/asistencia';
import { useForm } from '../../../../hooks/useForm';

export const FormAsistenciaNueva = ({cursoActivo}) => {
    const dispatch = useDispatch();
    const [asistencia, handleInputChange, reset] = useForm({
        fecha:'',
        curso:cursoActivo,
    })

    const  { fecha } = asistencia;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( fecha === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearAsistencia(asistencia));
        reset()
    }

    return (
        <form>
            <br/>
            <h5>Formulario registro asistencia</h5>
            <div className="row mb-3 mt-3">
                
                <label htmlFor="fecha" className="col-2 col-form-label">Fecha</label>
                <div className="col-4">
                    <input 
                        type="date" 
                        className="form-control" 
                        id="fecha"
                        name="fecha"
                        value={ fecha }
                        onChange={ handleInputChange }
                    />
                </div>
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleEnviar }
                    >Crear</button>
                </div>
            </div>
        </form>
    )
}
