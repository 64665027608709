import React from 'react'
import { useSelector } from 'react-redux'
import { DetalleCalificacionAsignatura } from './DetalleCalificacionAsignatura'
import { PromedioAsignatura } from './PromedioAsignatura'

export const AsignaturasEntries = ({asignatura, estudiante, calificacionesMayorAsignatura, index}) => {
    const {calificacionesDetalleAsignatura, calificacionesAsignatura } = useSelector(state => state.libroDigital)
    const calificacionesAsignaturaActual = calificacionesAsignatura.filter(e => e.asignatura === asignatura._id)
    const calificacionesAsignaturaActual2 = calificacionesAsignatura.filter(e => e.asignatura === asignatura._id)
    const calificacionDetallesAsignaturaActual = calificacionesDetalleAsignatura.filter(e => e.asignatura === asignatura._id && e.estudiante === estudiante._id)
    console.log(calificacionDetallesAsignaturaActual)
    for (let i = 0; i < calificacionesMayorAsignatura.length; i++) {
        if(calificacionesAsignaturaActual.length < calificacionesMayorAsignatura.length){
            calificacionesAsignaturaActual.push({_id:`id${i +1}`})
        }
    }
    return (
        <tr>
            <th>{index}</th>
            <td>{asignatura.nombre}</td>
            {calificacionesAsignaturaActual && calificacionesAsignaturaActual.map( e => (
                <DetalleCalificacionAsignatura 
                    key={e._id}
                    calificacion={e}
                    detallesCalificaciones={ calificacionDetallesAsignaturaActual }
                />
            ))}
            <td>
                <PromedioAsignatura
                    asignatura={asignatura}
                    estudiante={estudiante}
                    calificacionesAsignatura={calificacionDetallesAsignaturaActual}
                />
            </td>
        </tr>
    )
}
