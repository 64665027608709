import React from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { activarClaseAsignatura } from '../../../../action/clasesAsignatura';
import { libroDigitalClaseContenidoAsignatura, libroDigitalFormClaseAsignaturaActualizar } from '../../../../action/router';
import { formatFecha } from '../../../../helpers/formatData';

export const RowClaseAsignatura = ({claseAsignatura, docentes, index}) => {
  const dispatch=useDispatch()
  console.log("claseAsignatura -------------->",claseAsignatura)
  const docentePrincipal = docentes.find(e=>e._id === claseAsignatura.docente);

  const handleContenido = () => {
    dispatch(activarClaseAsignatura(claseAsignatura._id));
    Swal.fire({
      title: claseAsignatura.titulo,
      text: claseAsignatura.objetivo,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }
  
  const handleActualizar = () => {
    dispatch(activarClaseAsignatura(claseAsignatura._id));
    dispatch(libroDigitalFormClaseAsignaturaActualizar());
  }
  
  console.log("docentePrincipal ----->",docentePrincipal)

  return (
    <tr>
      <th>{index}</th>
      <td>{claseAsignatura.titulo}</td>
      <td>{formatFecha(claseAsignatura.fecha)}</td>
      <td>{docentePrincipal ? docentePrincipal.nombres : ''} {docentePrincipal ? docentePrincipal.apellidoPaterno : ''} {docentePrincipal ? docentePrincipal.apellidoMaterno : ''}</td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-info btn-sm' onClick={handleContenido} >Contenido</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-warning btn-sm' onClick={handleActualizar} >Actualizar</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-danger btn-sm' disabled >Eliminar</button>
        </div>
      </td>
    </tr>
  )
}
