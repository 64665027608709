import React from 'react'
import { useDispatch } from 'react-redux'
import { activarAsignatura } from '../../../../action/asignatura'
import { libroDigitalTablaAsignaturas } from '../../../../action/router'
import { RowSinData } from '../../../RowSinData'
import { RowClaseAsignatura } from './RowClaseAsignatura'

export const TablaClasesAsignatura = ({clasesAsignatura, docentes, asignaturas, asignaturaActiva }) => {
  const dispatch = useDispatch()
  const asignaturaPrincipal = asignaturas.find(e => e._id === asignaturaActiva);

  const handleRegresar = () => {
    dispatch(activarAsignatura(''))
    dispatch(libroDigitalTablaAsignaturas())
}
  return (
    <>
      <h4>Asignatura: {asignaturaPrincipal.nombre}</h4>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>N°</th>
            <th>Titulo</th>
            <th>Fecha</th>
            <th>Docente</th>
            <th>Contenido</th>
            <th>Modificar</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {clasesAsignatura 
            ? clasesAsignatura.map((e,index) =>(
              <RowClaseAsignatura 
                key={e._id}
                claseAsignatura={e}
                docentes={docentes}
                index={index+1}
              />
            ))
            :
            <RowSinData />
          }
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className='d-grid gap-2'>
                <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  )
}
