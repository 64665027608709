import React from "react";
import { TablaAsistencias } from "./Informe/TablaAsistencias";
import { InformeEstudiante } from "./Informe/InformeEstudiante";

export const AsistenciaInformeScreen = () => {
  const estudiantes =[
    {_id:'1',nombres:'Ignacio Andres', apellidoPaterno: 'Romero', apellidoMaterno: 'Castillo'},
    {_id:'2',nombres:'tomas', apellidoPaterno: 'Romero', apellidoMaterno: 'Castillo'},
    {_id:'3',nombres:'pedro', apellidoPaterno: 'Romero', apellidoMaterno: 'Castillo'},

  ]
  
  const porcentajesAsistencias = [
    {id:'1',estudiante:'1', porcentaje:'50%'},
    {id:'2',estudiante:'2', porcentaje:'60%'},
    {id:'3',estudiante:'3', porcentaje:'70%'},
  ]
  // hacer boton volver
  // 
  return (
    <div className="row">
      <div className="col-12">
        <TablaAsistencias 
          estudiantes={estudiantes}
          porcentajesAsistencias={porcentajesAsistencias}
        />
      </div>
      <div className="col-12">
        <InformeEstudiante />
      </div>
    </div>
  );
};
