import React from "react";

export const InformeEstudiante = () => {
  return (
    <div className="row">
      <div className="col-12">
        <h3>Estudiante: estudiante</h3>
        <h5>Curso: curso</h5>
      </div>
      <div className="col-12">
        <h5>Asistencia Total: 40%</h5>
        <div>Dias Asistidos: 100</div>
        <div>Dias Inasistencia: 20</div>
      </div>
      <div className="col-12">
        <h5>Detalle asistencia</h5>
      </div>
      <div className="col-12">
        <table className="table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Observacion</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>11/11/2023</td>
              <td>asistio</td>
              <td>observacion</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
