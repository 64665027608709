import React from 'react'
import Swal from 'sweetalert2'
import { formatFecha, getTipoCalificacion } from '../../../../helpers/formatData'

export const DetalleCalificacion = ({calificacionAsignatura}) => {
  const handleDetalle = () => {
    Swal.fire({
      title: `${calificacionAsignatura.numero}- ${calificacionAsignatura.descripcion}`,
      text: `Tipo: ${getTipoCalificacion(calificacionAsignatura.tipo)} Fecha: ${formatFecha(calificacionAsignatura.fecha)}`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }
  return (
    <td>
        <div className='d-grid gap-2'>
            <button className='btn btn-info btn-sm' onClick={handleDetalle} >Detalle</button>
        </div>
    </td>                 

  )
}
