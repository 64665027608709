import React from "react";
import { useForm } from '../../../../hooks/useForm';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { crearAnotacion } from "../../../../action/anotacion";

export const FormAnotacion = () => {
    const dispatch = useDispatch();
    const {asignaturas} = useSelector(state=> state.libroDigital);
    const {estudianteActivo} = useSelector(state=> state.estudiante)
    const [anotacion, handleInputChange, reset] = useForm({
        fecha:'',
        tipo: '',
        titulo:'',
        detalle:'',
        asignatura: '',
        estudiante: estudianteActivo,
    })

    const  { fecha, titulo, detalle, tipo, asignatura } = anotacion;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( detalle === '' || fecha === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearAnotacion(anotacion));
        reset()
    }
    return(
        <form>
            <h5>Formulario de Ingreso</h5>
            <div className="row mb-3 mt-3">
                <label htmlFor="fecha" className="col col-form-label">Fecha</label>
                <div className="col-3">
                    <input 
                        type="date" 
                        className="form-control" 
                        id="fecha"
                        name="fecha"
                        value={ fecha }
                        onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="tipo" className="col col-form-label">Tipo</label>
                <div className="col-3">
                    <select 
                        className="form-select" 
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={tipo}  
                        name="tipo" 
                    >
                        <option value="0" defaultValue>seleccione...</option>
                        <option value="1" defaultValue>Positiva</option>
                        <option value="2">Negativa</option>
                        <option value="3">Otra</option>
                    </select>
                </div>
                <label htmlFor="asignatura" className="col col-form-label">Asignatura</label>
                <div className="col-3">
                    <select 
                        className="form-select" 
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={asignatura}  
                        name="asignatura" 
                    >
                        <option value="0" defaultValue>seleccione...</option>
                        {asignaturas && asignaturas.map(e => (
                            <option value={e._id} key={e._id} defaultValue>{e.nombre}</option>

                        ))}
                    </select>
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="titulo" className="col col-form-label">Titulo</label>
                <div className="col-12">
                    <input 
                        type="textbox" 
                        className="form-control" 
                        id="titulo"
                        name="titulo"
                        value={ titulo }
                        onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="detalle" className="col col-form-label">Detalle</label>
                <div className="col-12">
                    <input 
                        type="textbox" 
                        className="form-control" 
                        id="descripcion"
                        name="detalle"
                        value={ detalle }
                        onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col-2">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleEnviar }
                    >Crear</button>
                </div>
            </div>
        </form>
    ) 

};
