import React from 'react'
import { useSelector } from 'react-redux';

export const DetalleCalificacionTallerSegundo = ({calificacion, detallesCalificaciones}) => {
    const {calificacionDetallesTaller} = useSelector(state => state.libroDigital);
    const {estudianteActivo} = useSelector(state => state.estudiante);
    const detalleCalificacion = calificacionDetallesTaller.find(e => e.tallerCalificacion == calificacion._id && e.estudiante == estudianteActivo);
    if(!detalleCalificacion) return <td></td>
    return (
        <td>{detalleCalificacion.calificacion}</td>
    )
}
