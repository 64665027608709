import Swal from "sweetalert2";
import { fetchConToken, fetchConTokenFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";



export const crearEstudiante = ( estudiante ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('estudiante/crearEstudiante', estudiante, 'POST');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearEstudianteOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearEstudianteError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearEstudianteError());
        }
    }
}

const crearEstudianteOk = (e) => ({
    type: types.crearEstudianteOk,
    payload: e
});

const crearEstudianteError = () => ({ type: types.crearEstudianteError });

export const obtenerEstudiantes = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('estudiante/obtenerEstudiantes');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(obtenerEstudiantesOk( result.payload ))
               
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerEstudiantesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerEstudiantesError());
        }
    }
}
export const obtenerEstudiantesCurso = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('estudiante/obtenerEstudiantesCurso', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(obtenerEstudiantesOk( result.payload ))
               
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerEstudiantesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerEstudiantesError());
        }
    }
}

const obtenerEstudiantesOk = (e) => ({ 
    type: types.obtenerEstudiantesOk ,
    payload: e
});

const obtenerEstudiantesError = () => ({ type: types.obtenerEstudiantesError })

export const actualizarEstudiante = (estudiante) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`estudiante/actualizarEstudiante/${estudiante._id}`, estudiante, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(actualizarEstudianteOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(actualizarEstudianteError());
            }

        } catch (error) {
            console.log(error)
            dispatch(actualizarEstudianteError())
        }
    }
}

const actualizarEstudianteOk = (e) => ({ 
    type: types.actualizarEstudianteOk,
    payload: e
});

const actualizarEstudianteError = () => ({ type : types.actualizarEstudianteError });

export const activarEstudiante = (e) => ({ 
    type: types.activarEstudiante,
    payload: e
});