import React from 'react'
import { useDispatch } from 'react-redux';
import { activarAnioEscolar } from '../../../action/anioEscolar';
import { libroDigitalTablaCursos } from '../../../action/router';
import { getAnio } from '../../../helpers/formatData';

export const RowAnioEscolar = ({anioEscolar, index}) => {
    const dispatch=useDispatch();
    const handleCursos = () => {
        dispatch(activarAnioEscolar(anioEscolar._id))
        dispatch(libroDigitalTablaCursos())
    }
    return (
        <tr>
            <th>{index}</th>
            <td>{getAnio(anioEscolar.anioEscolar)}</td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-primary btn-sm' onClick={handleCursos} >Cursos</button>
                </div>
            </td>
           
        </tr>
    )
}
