import React from 'react'
import Swal from 'sweetalert2'
import { formatFecha, getTipoCalificacion } from '../../../../helpers/formatData'

export const DetalleCalificacionTaller = ({calificacionTaller}) => {
  const handleDetalle = () => {
    Swal.fire({
      title: `${calificacionTaller.numero}- ${calificacionTaller.descripcion}`,
      text: `Tipo: ${getTipoCalificacion(calificacionTaller.tipo)} Fecha: ${formatFecha(calificacionTaller.fecha)}`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }
  return (
    <td>
        <div className='d-grid gap-2'>
            <button className='btn btn-info btn-sm' onClick={handleDetalle} >Detalle</button>
        </div>
    </td>                 

  )
}
