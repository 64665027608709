import React from 'react'
import { useDispatch } from 'react-redux';
import { activarAsistencia } from '../../../../action/asistencia';
import { libroDigitalTablaAsistenciaEstudiantes } from '../../../../action/router';
import { formatFecha } from '../../../../helpers/formatData';

export const RowAsistencia = ({asistencia, docentes, index}) => {
  const dispatch=useDispatch();
  const docentePrincipal = docentes.find(e=>e._id === asistencia.creador)

  const handleDetalle = () => {
    dispatch(activarAsistencia(asistencia._id));
    dispatch(libroDigitalTablaAsistenciaEstudiantes())
  }
  const handleEliminar = () => {
    console.log('eliminar')
  }

  return (
    <tr>
      <th>{index}</th>
      <td>{formatFecha(asistencia.fecha)}</td>
      <td>{docentePrincipal.nombres} {docentePrincipal.apellidoPaterno} {docentePrincipal.apellidoMaterno}</td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-info btn-sm' onClick={handleDetalle}>Detalle</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-danger btn-sm' onClick={handleEliminar}>Eliminar</button>
        </div>
      </td>
    </tr>
  )
}
