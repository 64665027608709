import React from 'react'
import { useDispatch } from 'react-redux'
import { FormCalificacionDetalleTaller } from './FormCalificacionDetalleTaller'
import { PromedioTaller } from './PromedioTaller'

export const RowEstudiantesTaller = ({estudiante, calificacionesTaller, tallerActivo, index}) => {
    const dispatch = useDispatch()
    const { nombres, apellidoPaterno, apellidoMaterno } = estudiante
    return (
        <tr>
            <td>{index}</td>
            <td>{`${ nombres } ${apellidoPaterno} ${apellidoMaterno}`}</td>
            {calificacionesTaller.length > 0 && calificacionesTaller.map(e => (
                <td key={e._id}>
                    <FormCalificacionDetalleTaller 
                        estudiante={estudiante}
                        calificacionTallerActiva={e}
                        tallerActivo={tallerActivo}
                    />
                </td>
            ))}
            <td>
                <PromedioTaller
                    estudiante={estudiante}
                    tallerActivo={tallerActivo}
                    calificacionesTaller={calificacionesTaller}
                />
            </td>
        </tr>
    )
}
