import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearTaller = ( taller ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearTaller', taller, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearTallerOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearTallerError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearTallerError());
        }
    }
}

const crearTallerOk = (e) => ({
    type: types.crearTallerOk,
    payload: e
});

const crearTallerError = () => ({ type: types.crearTallerError });


export const obtenerTalleres = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerTalleres');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerTalleresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerTalleresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerTalleresError());
        }
    }
}

export const obtenerTalleresCurso = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerTalleresCurso', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerTalleresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerTalleresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerTalleresError());
        }
    }
}
export const obtenerTalleresCursoPeriodoEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerTalleresCursoPeriodoEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerTalleresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerTalleresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerTalleresError());
        }
    }
}

export const obtenerTalleresAnioEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerTalleresAnioEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerTalleresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerTalleresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerTalleresError());
        }
    }
}

const obtenerTalleresOk = (e) => ({ 
    type: types.obtenerTalleresOk ,
    payload: e
});

const obtenerTalleresError = () => ({ type: types.obtenerTalleresError });



export const actualizarTaller = (taller) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarTaller/${taller._id}`, taller, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarTallerError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarTallerError())
        }
    }
}

const actualizarTallerOk = (e) => ({ 
    type: types.actualizarTallerOk,
    payload: e
});

const actualizarTallerError = () => ({ type : types.actualizarTallerError });

export const activarTaller = (e) => ({ 
    type: types.activarTaller,
    payload: e
});

export const limpiarTalleres = () => ({ 
    type: types.limpiarTalleres
});
