import React from 'react'
import { useDispatch } from 'react-redux';
import { activarCurso } from '../../../../action/curso';
import { libroDigitalTablaCursos } from '../../../../action/router';
import { RowAsistencia } from './RowAsistencia'

export const TablaAsistencias = ({asistencias, docentes}) => {
  const dispatch=useDispatch();

  const handleRegresar = () => {
    dispatch(activarCurso(''))
    dispatch(libroDigitalTablaCursos())
  }
  return (
    <table className='table table-bordered table-hover'>
      <thead>
          <tr>
              <th>N°</th>
              <th>Fecha</th>
              <th>Creador</th>
              <th>Detalle</th>
              <th>Eliminar</th>
          </tr>
      </thead>
      <tbody>
          {asistencias && asistencias.map((e,index) => (
              <RowAsistencia
                  key={e._id}
                  asistencia={e}
                  docentes={docentes}
                  index={index + 1}
              />

          ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className='d-grid gap-2'>
              <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
