import React from 'react'

export const PromedioAsignatura = ({asignatura, estudiante, calificacionesAsignatura}) => {
  let sumaNota = 0;
  for (let index = 0; index < calificacionesAsignatura.length; index++) {
    const element = calificacionesAsignatura[index];
    if(element){
      sumaNota = sumaNota + element.calificacion
    }
  }
  const promedio = sumaNota / calificacionesAsignatura.length
  return (
    <div>{promedio.toFixed(1)}</div>
  )
}
