import React from 'react'
import { useDispatch } from 'react-redux'
import { libroDigitalFormClaseAsignaturaNueva } from '../../../../action/router'

export const BotonClaseAsignaturaNueva = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(libroDigitalFormClaseAsignaturaNueva());
  }
  return (
    <div className='d-grid gap-2'>
        <button className='btn btn-primary btn-sm' onClick={handleClick} >Nueva Clase</button>
    </div>
  )
}
