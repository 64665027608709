import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import { useForm } from '../../../../hooks/useForm';
import { crearCalificacionTaller } from '../../../../action/calificacionTaller';

export const FormCalificacionTaller = ({tallerActivo, cursoActivo}) => {
    const dispatch = useDispatch();
    const [calificacionTaller, handleInputChange, reset] = useForm({
        descripcion:'',
        fecha:'',
        tipo: '1',
        taller: tallerActivo,
        curso: cursoActivo,
    })

    const  { descripcion, fecha, tipo } = calificacionTaller;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( descripcion === '' || fecha === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearCalificacionTaller(calificacionTaller));
        reset()
    }


    return (
        <form>
            <h5>Ingreso nueva calificación</h5>
            <div className="row mb-3 mt-3">
                <label htmlFor="descripcion" className="col col-form-label">Descripcion</label>
                <div className="col-2">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="descripcion"
                        name="descripcion"
                        value={ descripcion }
                        onChange={ handleInputChange }
                    />
                </div>
                
                <label htmlFor="fecha" className="col col-form-label">Fecha</label>
                <div className="col-2">
                    <input 
                        type="date" 
                        className="form-control" 
                        id="fecha"
                        name="fecha"
                        value={ fecha }
                        onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="tipo" className="col col-form-label">Tipo</label>
                <div className="col-2">
                    <select 
                        className="form-select" 
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={tipo}  
                        name="tipo" 
                    >
                        <option value="1" defaultValue>Normal</option>
                        <option value="2">Solemne</option>
                    </select>
                </div>
                <div className="col-2">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleEnviar }
                    >Crear</button>
                </div>
            </div>
        </form>
    )
}
