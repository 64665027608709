import React from 'react'
import { useDispatch } from 'react-redux'
import { activarCurso } from '../../../action/curso'
import { libroDigitalAsistenciaScreen, libroDigitalTablaAsistencias, libroDigitalTablaEstudiantes, libroDigitalTablaPeriodos } from '../../../action/router';

export const RowCurso = ({curso, index}) => {
  const dispatch=useDispatch();

  const handlePeriodos = () => {
    dispatch(activarCurso(curso._id));
    dispatch(libroDigitalTablaPeriodos())
  }
  
  const handleAsistencia = () => {
    dispatch(activarCurso(curso._id));
    dispatch(libroDigitalAsistenciaScreen())
  }
  
  const handleEstudiantes = () => {
    dispatch(activarCurso(curso._id));
    dispatch(libroDigitalTablaEstudiantes())
  }
  
  return (
    <tr>
      <th>{index}</th>
      <td>{curso.nombre}</td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-primary btn-sm' onClick={handleAsistencia} >Asistencia</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-primary btn-sm' onClick={handlePeriodos}>Periodos</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-primary btn-sm' onClick={handleEstudiantes}>Estudiantes</button>
        </div>
      </td>
    </tr>
  )
}
