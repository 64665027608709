import React from 'react'
import { useSelector } from 'react-redux'
import { DetalleCalificacionAsignaturaSegundo } from './DetalleCalificacionAsignaturaSegundo'
import { PromedioAsignaturaSegundo } from './PromedioAsignaturaSegundo'

export const AsignaturasEntriesSegundo = ({asignatura, estudiante, calificacionesMayorAsignatura, index}) => {
    const {calificacionesDetalleAsignatura, calificacionesAsignatura } = useSelector(state => state.libroDigital)
    const calificacionesAsignaturaActual = calificacionesAsignatura.filter(e => e.asignatura === asignatura._id)
    const calificacionesAsignaturaActual2 = calificacionesAsignatura.filter(e => e.asignatura === asignatura._id)
    const calificacionDetallesAsignaturaActual = calificacionesDetalleAsignatura.filter(e => e.asignatura === asignatura._id && e.estudiante === estudiante._id)
    for (let i = 0; i < calificacionesMayorAsignatura.length; i++) {
        if(calificacionesAsignaturaActual.length < calificacionesMayorAsignatura.length){
            calificacionesAsignaturaActual.push({_id:`id${i +1}`})
        }
    }
    return (
        <tr>
            <th>{index}</th>
            <td>{asignatura.nombre}</td>
            {calificacionesAsignaturaActual && calificacionesAsignaturaActual.map( e => (
                <DetalleCalificacionAsignaturaSegundo
                    key={e._id}
                    calificacion={e}
                    detallesCalificaciones={ calificacionDetallesAsignaturaActual }
                />
            ))}
            {/* <td>
                <PromedioAsignaturaSegundo
                    asignatura={asignatura}
                    estudiante={estudiante}
                    calificacionesAsignatura={calificacionesAsignaturaActual2}
                />
            </td> */}
        </tr>
    )
}
