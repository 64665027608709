import React from 'react'
import { useDispatch } from 'react-redux'
import { activarAnioEscolar } from '../../../action/anioEscolar'
import { libroDigitalTablaAniosEscolares } from '../../../action/router'
import { RowCurso } from './RowCurso'

export const TablaCursos = ({cursos}) => {
  const dispatch=useDispatch();

  const handleRegresar = () => {
      dispatch(activarAnioEscolar(''))
      dispatch(libroDigitalTablaAniosEscolares())
  }
  return (
    <table className='table table-bordered table-hover'>
      <thead>
          <tr>
              <th>N°</th>
              <th>Nombre</th>
              <th>Asistencia</th>
              <th>Periodos</th>
              <th>estudiantes</th>
          </tr>
      </thead>
      <tbody>
          {cursos && cursos.map((e,index) => (
              <RowCurso
                  key={e._id}
                  curso={e}
                  index={index + 1}
              />
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className='d-grid gap-2'>
              <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
