import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import { useForm } from '../../../../hooks/useForm';
import { crearCalificacionDetalleTaller } from '../../../../action/calificacionDetalleTaller';
import { FormCalificacionDetalleTallerActualizar } from './FormCalificacionDetalleTallerActualizar';

export const FormCalificacionDetalleTaller = ({ estudiante, calificacionTallerActiva, tallerActivo }) => {
    const dispatch = useDispatch();
    const { calificacionDetallesTaller } = useSelector(state => state.libroDigital);
    const calificacionDetalleTallerActivo = calificacionDetallesTaller.find(e => e.estudiante === estudiante._id && e.tallerCalificacion === calificacionTallerActiva._id)
    

    const [calificacionTallerDetalle, handleInputChange, reset] = useForm({
        calificacion:'',
        estudiante:estudiante._id,
        tallerCalificacion: calificacionTallerActiva._id,
        taller: tallerActivo,
    })

    const  { calificacion } = calificacionTallerDetalle;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( calificacion === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        
        if( calificacion < 2 || calificacion > 7){
            return Swal.fire({
                icon: 'warning',
                title: 'La calificacion debe ser entre 2 y 7',
                showConfirmButton: false,
                timer: 1000
            });
        } 
        calificacionTallerDetalle.calificacion = calificacion.replace(',', '.')
        dispatch(crearCalificacionDetalleTaller(calificacionTallerDetalle));
        reset()
    }
    if(calificacionDetalleTallerActivo) {
        return (
            <FormCalificacionDetalleTallerActualizar 
                calificacionDetalleTallerActivo = {calificacionDetalleTallerActivo}
            />
        )
    }


    // if(calificacionDetalleActivo) {
    //     if(calificacionActiva.tipo === 2) {
    //         return <div className="text-warning"><strong>{calificacionDetalleActivo.calificacion}</strong></div>
    //     } else {
    //         if(calificacionDetalleActivo.calificacion < 4 ) {
    //             return <div className="text-danger">{calificacionDetalleActivo.calificacion}</div>
    //         } else {
    //             return calificacionDetalleActivo.calificacion
    //         }
    //     }
    // }

    return (
        <form>
            <div className="row mb-3 mt-3">
                <div className="col-9">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="calificacion"
                        name="calificacion"
                        value={ calificacion }
                        onChange={ handleInputChange }
                    />
                </div>
                
            
                <div className="col-3">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleEnviar }
                    >+</button>
                </div>
            </div>
        </form>
    )
}
