import React from 'react'
import { useDispatch } from 'react-redux'
import { activarTaller } from '../../../../action/taller'
import { libroDigitalTablaTalleres } from '../../../../action/router'
import { RowSinData } from '../../../RowSinData'
import { RowClaseTaller } from './RowClaseTaller'

export const TablaClasesTaller = ({clasesTaller, docentes}) => {
  const dispatch = useDispatch()

  const handleRegresar = () => {
    dispatch(activarTaller(''))
    dispatch(libroDigitalTablaTalleres())
}
  return (
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          <th>N°</th>
          <th>Titulo</th>
          <th>Docente</th>
          <th>Contenido</th>
          <th>Modificar</th>
          <th>Eliminar</th>
        </tr>
      </thead>
      <tbody>
        {clasesTaller 
          ? clasesTaller.map((e,index) =>(
            <RowClaseTaller 
              key={e._id}
              claseTaller={e}
              docentes={docentes}
              index={index+1}
            />
          ))
          :
            <RowSinData />
        }
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className='d-grid gap-2'>
              <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
