import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearClaseTaller = ( claseTaller ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearClaseTaller', claseTaller, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearClaseTallerOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearClaseTallerError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearClaseTallerError());
        }
    }
}

const crearClaseTallerOk = (e) => ({
    type: types.crearClaseTallerOk,
    payload: e
});

const crearClaseTallerError = () => ({ type: types.crearClaseTallerError });


export const obtenerClasesTalleres = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerClasesTalleres');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerClasesTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerClasesTallerError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerClasesTallerError());
        }
    }
}

export const obtenerClasesTaller = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerClasesTaller', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerClasesTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerClasesTallerError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerClasesTallerError());
        }
    }
}


const obtenerClasesTallerOk = (e) => ({ 
    type: types.obtenerClasesTallerOk ,
    payload: e
});

const obtenerClasesTallerError = () => ({ type: types.obtenerClasesTallerError });



export const actualizarClaseTaller = (claseTaller) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarClaseTaller/${claseTaller._id}`, claseTaller, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarClaseTallerOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarClaseTallerError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarClaseTallerError())
        }
    }
}

const actualizarClaseTallerOk = (e) => ({ 
    type: types.actualizarClaseTallerOk,
    payload: e
});

const actualizarClaseTallerError = () => ({ type : types.actualizarClaseTallerError });

export const activarClaseTaller = (e) => ({ 
    type: types.activarClaseTaller,
    payload: e
});

export const limpiarClasesClaseTaller = () => ({ 
    type: types.limpiarClasesTaller
});
