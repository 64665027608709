import React from 'react'
import { useDispatch } from 'react-redux';
import { RowEstudiantesTaller } from './RowEstudiantesTaller'
import { activarTaller } from '../../../../action/taller'
import { libroDigitalTablaTalleres } from '../../../../action/router';
import { DetalleCalificacionTaller } from './DetalleCalificacionTaller';

export const TablaEstudiantesTaller = ({estudiantes, calificacionesTaller, tallerActivo, talleres}) => {
    const dispatch = useDispatch();
    const tallerPrincipal = talleres.find(e => e._id === tallerActivo);

    const handleRegresar = () => {
        dispatch(activarTaller(''))
        dispatch(libroDigitalTablaTalleres())
    }
    return (
        <>
            <h4>Taller: {tallerPrincipal.nombre}</h4>
            <table className='table table-bordered table-hover'>
                <thead>
                    <tr>
                        <th>N°</th>
                        <th>Nombre</th>
                        {calificacionesTaller.length > 0 &&  calificacionesTaller.map( e => (
                            <th 
                                key={e._id} 
                                e={e} 
                            >{e.numero}</th>
                        ))}
                        <th>Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {estudiantes.length === 0 ? null : estudiantes.map((e, index) => (
                        <RowEstudiantesTaller
                            key={e._id}
                            estudiante={e}
                            calificacionesTaller={calificacionesTaller}
                            tallerActivo={tallerActivo}
                            index={index+1}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                            </div>
                        </td>
                        <td></td>
                        {calificacionesTaller.length > 0 &&  calificacionesTaller.map( e => (
                            <DetalleCalificacionTaller
                                key={e._id}
                                calificacionTaller={e}
                            />
                        ))}
                        {/* <td>Promedio Curso</td> */}
                    </tr>
                </tfoot>
            </table>
        </>
    )
}
