import React from 'react'
import { useDispatch } from 'react-redux'
import { activarPeriodoEscolar } from '../../../action/periodoEscolar'
import { libroDigitalTablaAsignaturas, libroDigitalTablaTalleres } from '../../../action/router'

export const RowPeriodo = ({periodoEscolar, index}) => {
  const dispatch=useDispatch()

  const handleAsignaturas = () => {
    dispatch(activarPeriodoEscolar(periodoEscolar._id))
    dispatch(libroDigitalTablaAsignaturas())
  }

  const handleTalleres = () => {
    dispatch(activarPeriodoEscolar(periodoEscolar._id))
    dispatch(libroDigitalTablaTalleres())
  }

  return (
    <tr>
      <th>{index}</th>
      <td>{periodoEscolar.periodo}</td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-secondary btn-sm' onClick={handleAsignaturas} >Asignaturas</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-secondary btn-sm' onClick={handleTalleres} >Talleres</button>
        </div>
      </td>
    </tr>
  )
}
