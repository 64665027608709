import React from 'react'
import { RowEstudiantes } from './RowEstudiantes'
import { activarAsignatura } from '../../../../action/asignatura'
import { useDispatch } from 'react-redux';
import { libroDigitalTablaAsignaturas } from '../../../../action/router';
import { DetalleCalificacion } from './DetalleCalificacion';

export const TablaEstudiantes = ({estudiantes, calificacionesAsignatura, asignaturaActiva, asignaturas}) => {
    const dispatch = useDispatch();
    const asignaturaPrincipal = asignaturas.find(e => e._id === asignaturaActiva);

    const handleRegresar = () => {
        dispatch(activarAsignatura(''))
        dispatch(libroDigitalTablaAsignaturas())
    }
    return (
        <>
            <h4>Asignatura: {asignaturaPrincipal.nombre}</h4>
            <table className='table table-bordered table-hover'>
                <thead>
                    <tr>
                        <th>N°</th>
                        <th>Nombre</th>
                        {calificacionesAsignatura.length > 0 &&  calificacionesAsignatura.map( e => (
                            <th 
                                key={e._id} 
                                e={e} 
                            >{e.numero}</th>
                        ))}
                        <th>Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {estudiantes.length === 0 ? null : estudiantes.map((e, index) => (
                        <RowEstudiantes 
                            key={e._id}
                            estudiante={e}
                            calificacionesAsignatura={calificacionesAsignatura}
                            asignaturaActiva={asignaturaActiva}
                            index={index+1}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                            </div>
                        </td>
                        <td></td>
                        {calificacionesAsignatura.length > 0 &&  calificacionesAsignatura.map( e => (
                            <DetalleCalificacion 
                                key={e._id}
                                calificacionAsignatura={e}
                            />
                        ))}
                        {/* <td>Promedio Curso</td> */}
                    </tr>
                </tfoot>
            </table>
        </>
    )
}
