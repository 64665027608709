import React from 'react'
import { useSelector } from 'react-redux';

export const Promedio = ({estudiante, asignaturaActiva, calificacionesAsignatura}) => {
    const { calificacionesDetalleAsignatura } = useSelector(state => state.libroDigital);
    const calificacionesDetalle = calificacionesDetalleAsignatura.filter(e => e.estudiante === estudiante._id && e.asignatura === asignaturaActiva)
    const calificacionesTipo1 = calificacionesAsignatura.filter(e => e.tipo === 1)
    const calificacionTipo2 = calificacionesAsignatura.find(e => e.tipo === 2)
    let sumaCalificacionesTipo1 = 0
    let cantidadTipo1 = 0
    let calificacionDetalleTipo2 = 0
    if( calificacionesTipo1.length > 0 && calificacionesDetalle.length > 0 ){
        for(let i = 0; i < calificacionesTipo1.length; i++){
            const calificacionTipo1 = calificacionesTipo1[i];
            const detalleCalificacionTipo1 = calificacionesDetalle.find(e=>e.asignaturaCalificacion === calificacionTipo1._id)
            if(detalleCalificacionTipo1){
                sumaCalificacionesTipo1 = sumaCalificacionesTipo1 + detalleCalificacionTipo1.calificacion
                cantidadTipo1 = cantidadTipo1 + 1
            }
        }
    }
    const promedioCalificacionesTipo1 = sumaCalificacionesTipo1 / cantidadTipo1
    if(calificacionTipo2 && calificacionesDetalle.length > 0 ){
        const detalleCalificacionTipo2 = calificacionesDetalle.find(e=> e.asignaturaCalificacion === calificacionTipo2._id)
        console.log(detalleCalificacionTipo2)
        if(detalleCalificacionTipo2){
            calificacionDetalleTipo2 = detalleCalificacionTipo2.calificacion
        }
    }
    let promedioAsignatura = 0
    if(calificacionDetalleTipo2 > 0){
        console.log('promedio con tipo 2',promedioCalificacionesTipo1, calificacionDetalleTipo2 )
        promedioAsignatura = (promedioCalificacionesTipo1 * 0.70) + (calificacionDetalleTipo2 * 0.30)
    } else {
        promedioAsignatura = promedioCalificacionesTipo1
    }
    return (
        <div>{promedioAsignatura.toFixed(1)}</div>
    )
}
