import React from 'react'
import { useDispatch } from 'react-redux'
import { actualizarAsistenciaEstudiante, crearAsistenciaEstudiante } from '../../../../action/asistenciaEstudiante'
import { FormAsistenciaEstudianteObservacionActualizar } from './FormAsistenciaEstudianteObservacionActualizar'

export const RowAsistenciaEstudiante = ({estudiante ,asistenciaActiva, asistenciaEstudiantes, cursoActivo, index}) => {
    const dispatch=useDispatch()
    let asistenciaEstudiantePrincipal = {}
    if(asistenciaEstudiantes.length > 0) {
        asistenciaEstudiantePrincipal = asistenciaEstudiantes.find(e=> e.estudiante === estudiante._id)
    }
    if(!asistenciaEstudiantePrincipal){
        asistenciaEstudiantePrincipal = {}
    }
    
    const handleAsistente = () => {
        const asistenciaEstudiante = {
            estado: true,
            observacion: '',
            asistencia: asistenciaActiva,
            curso: cursoActivo,
            estudiante: estudiante._id
        }
        dispatch(crearAsistenciaEstudiante(asistenciaEstudiante));
    }

    const handleInasistente = () => {
        const asistenciaEstudiante = {
            estado: false,
            observacion: '',
            asistencia: asistenciaActiva,
            curso: cursoActivo,
            estudiante: estudiante._id
        }
        dispatch(crearAsistenciaEstudiante(asistenciaEstudiante));
    }

    const handleActualizarAsistente = () => {
        asistenciaEstudiantePrincipal.estado= false
        dispatch(actualizarAsistenciaEstudiante(asistenciaEstudiantePrincipal));
    }

    const handleActualizarInasistente = () => {
        asistenciaEstudiantePrincipal.estado= true
        dispatch(actualizarAsistenciaEstudiante(asistenciaEstudiantePrincipal));
    }

    return (
        <tr>
            <th>{index}</th>
            <td>{estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno}</td>
            {Object.entries(asistenciaEstudiantePrincipal).length === 0 
                ?
                <td>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-success btn-sm' onClick={handleAsistente} >Asistente</button>
                    </div>
                    <div className='d-grid gap-2'>
                        <button className='btn btn-danger btn-sm' onClick={handleInasistente} >Inasistente</button>
                    </div>
                </td>
                : asistenciaEstudiantePrincipal.estado === true
                    ?
                        <td>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-success btn-sm' onClick={handleActualizarAsistente} >Asistente</button>
                            </div>
                        </td>
                    :
                        <td>
                            <div className='d-grid gap-2'>
                                <button className='btn btn-danger btn-sm' onClick={handleActualizarInasistente} >Inasistente</button>
                            </div>
                        </td>
            }
            
            <td>
                {Object.entries(asistenciaEstudiantePrincipal).length > 0 
                    ? 
                        <FormAsistenciaEstudianteObservacionActualizar 
                            asistenciaEstudianteObservacion={asistenciaEstudiantePrincipal}
                        />
                    : '' 
                }
            </td>
        </tr>
    )
}
