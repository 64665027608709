import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsignaturasEntries } from './AsignaturasEntries';
import { TalleresEntries } from './TalleresEntries';
import { TablaEstudiantesCurso } from '../TablaEstudiantesCurso';
import { libroDigitalTablaEstudiantes } from '../../../../../action/router';
import timbreDireccion from '../../../../../images/timbreDireccion.png';
import firmaDirector from '../../../../../images/firmaDirector.jpg';

export const CertificadoEstudiante = () => {
    const dispatch = useDispatch()
    const { estudianteActivo, estudiantes } = useSelector(state => state.estudiante);
    const { docentes, credencialesUsuario } = useSelector(state => state.usuario);
    const { credencialLogin } = useSelector(state => state.auth);
    const credencialEstablecimiento = credencialesUsuario.find( e=> e._id === credencialLogin )
    const { cursoActivo, cursos, asignaturas, calificacionesAsignatura, talleres, calificacionesTaller } = useSelector(state => state.libroDigital);
    const estudiante = estudiantes.find(e=> e._id === estudianteActivo);
    
    const cursoEstudiante = cursos.find(e=> e._id === cursoActivo)
    const docenteCurso = docentes.find(e=> e._id === cursoEstudiante.docente);
    const asignaturasCurso = asignaturas.filter(e => e.curso === cursoActivo);
    const talleresCurso = talleres.filter(e => e.curso === cursoActivo);

    

    let calificacionMayorAsignatura = {}
    let cantidadMaximaCalificacionesAsignatura = 0
    let calificacionMayorTaller = {}
    let cantidadMaximaCalificacionesTaller = 0
    calificacionesAsignatura.forEach(element => {
        if(element.numero > cantidadMaximaCalificacionesAsignatura){
            cantidadMaximaCalificacionesAsignatura = element.numero;
            calificacionMayorAsignatura = element;
        }
    });
    calificacionesTaller.forEach(element => {
        if(element.numero > cantidadMaximaCalificacionesTaller){
            cantidadMaximaCalificacionesTaller = element.numero;
            calificacionMayorTaller = element;
        }
    });

    const calificacionesMayorAsignatura = calificacionesAsignatura.filter(e=> e.asignatura === calificacionMayorAsignatura.asignatura);
    const calificacionesMayorTaller = calificacionesTaller.filter(e=> e.taller === calificacionMayorTaller.taller);

    const decretos = [
        { establecimiento:"640be43138221b36044b87ec", texto:'Decretos de Evaluación y Promoción Escolar N°2169 año 2007 - Decretos Planes y Programas de Estudio N°1000 año 2009. Reconocido oficialmente por el Ministerio de Educación de la República de Chile según Resolución N° 0005/ 06 de enero 2015, Rol Base de Datos N°20326-2 de la Cuarta Región de Coquimbo, Provincia de Elqui, Comuna de La Serena.'},
        { establecimiento:"640be43b38221b36044b87ef", texto:'Decretos de Evaluación y Promoción Escolar N°2169 año 2007 - Decretos Planes y Programas de Estudio N°1000 año 2009. Reconocido oficialmente por el Ministerio de Educación de la República de Chile según Resolución N° 1500/ 28 de enero 2022, Rol Base de Datos N°42079-4 de la Cuarta Región de Coquimbo, Provincia de Elqui, Comuna de COQUIMBO.'}
    ];
    const decretoActual= decretos.find(e=> e.establecimiento === credencialEstablecimiento.establecimiento)

    const handleRegresar = () => {
        dispatch(libroDigitalTablaEstudiantes())
    }
    return (
        <div className="row"> 
            <div className="col-12">
                <h3>INFORME DE CALIFICACIONES 1° SEMESTRE AÑO ACADÉMICO 2024</h3>
                <h6>{decretoActual.texto}</h6>
                <h5>Estudiante: <strong>{estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno}</strong></h5>
                <h5>RUT: <strong>{estudiante.rut}</strong></h5>
                <h5>Curso: <strong>{cursoEstudiante.nombre}</strong></h5>
                <h5>Docente: <strong>{docenteCurso.nombres} {docenteCurso.apellidoPaterno} {docenteCurso.apellidoMaterno}</strong></h5>
            </div>
            <div className="col-12">
                <table className="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Asignatura</th>
                            {calificacionesMayorAsignatura.length>0 && calificacionesMayorAsignatura.map(e => (
                                <th scope="col" key={e._id} >{e.numero}</th>
                            ))}
                            <th scope="col">Promedio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {asignaturasCurso.length>0 && asignaturasCurso.map((e, index) => (
                            <AsignaturasEntries
                                key={e._id}
                                asignatura={e}
                                estudiante={estudiante}
                                calificacionesMayorAsignatura={calificacionesMayorAsignatura}
                                index={index + 1}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>Promedio general</td>
                            {calificacionesMayorAsignatura.length>0 && calificacionesMayorAsignatura.map(e => (
                                <th scope="col" key={e._id} ></th>
                            ))}
                            <td>-</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="col-12">
                <table className="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Talleres</th>
                            {calificacionesMayorTaller.length>0 && calificacionesMayorTaller.map(e=>(
                                <th scope="col" key={e._id}>{e.numero}</th>
                            ))}
                            <th scope="col">Promedio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {talleresCurso.length>0 && talleresCurso.map((e, index) => (
                            <TalleresEntries
                                key={e._id}
                                taller={e}
                                estudiante={estudiante}
                                calificacionesMayorTaller={calificacionesMayorTaller}
                                index={index + 1}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <h5>Observaciones</h5>
            </div>
            <div className="col-12">
                <form>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </form>
            </div>
            <div className='col-12 mt-5'></div>
            <div className="col-12 mt-5">
                <div className="row justify-content-center">
                    <div className="col-1"></div>
                    <div className="col-4">
                        <p><img src={ firmaDirector } alt="logo" width={200} /></p>
                        <h6>_____________________</h6>
                        <h6>Jorge Ramírez Flores</h6>
                        <h6>Director</h6>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-4"><img src={ timbreDireccion } alt="logo" width={250} /></div>
                </div>

            </div>
            <button onClick={ handleRegresar }></button>
        </div>
    )
}
