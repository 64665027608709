import React from 'react'

export const RowEstudiantes = ({estudiante, porcentajesAsistencias,index}) => {
    const porcentaje = porcentajesAsistencias.find(e=>e.estudiante ===estudiante._id)
    //obtener porcentaje de asistencia estudiante
  return (
    <tr>
        <th scope="row">{index}</th>
        <td>{estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno}</td>
        <td>{porcentaje.porcentaje}</td>
        <td>
            <button className="btn btn-primary btn-large">Informe</button>
        </td>
    </tr>
  )
}
