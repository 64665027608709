import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearPeriodoEscolar = ( periodoEscolar ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearPeriodoEscolar', periodoEscolar, 'POST');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearPeriodoEscolarOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearPeriodoEscolarError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearPeriodoEscolarError());
        }
    }
}

const crearPeriodoEscolarOk = (e) => ({
    type: types.crearPeriodoEscolarOk,
    payload: e
});

const crearPeriodoEscolarError = () => ({ type: types.crearPeriodoEscolarError });


export const obtenerPeriodosEscolares = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerPeriodosEscolares');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerPeriodosEscolaresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerPeriodosEscolaresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerPeriodosEscolaresError());
        }
    }
}

export const obtenerPeriodosEscolaresEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerPeriodosEscolaresEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerPeriodosEscolaresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerPeriodosEscolaresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerPeriodosEscolaresError());
        }
    }
}

export const obtenerPeriodosEscolaresAnioEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerPeriodosEscolaresAnioEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerPeriodosEscolaresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerPeriodosEscolaresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerPeriodosEscolaresError());
        }
    }
}

const obtenerPeriodosEscolaresOk = (e) => ({ 
    type: types.obtenerPeriodosEscolaresOk ,
    payload: e
});

const obtenerPeriodosEscolaresError = () => ({ type: types.obtenerPeriodosEscolaresError });



export const actualizarPeriodoEscolar = (periodoEscolar) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarPeriodoEscolar/${periodoEscolar._id}`, periodoEscolar, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarPeriodoEscolarOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarPeriodoEscolarError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarPeriodoEscolarError())
        }
    }
}

const actualizarPeriodoEscolarOk = (e) => ({ 
    type: types.actualizarPeriodoEscolarOk,
    payload: e
});

const actualizarPeriodoEscolarError = () => ({ type : types.actualizarPeriodoEscolarError });

export const activarPeriodoEscolar = (e) => ({ 
    type: types.activarPeriodoEscolar,
    payload: e
});

export const limpiarPeriodosEscolares = () => ({ 
    type: types.limpiarPeriodosEscolares
});
