import React from 'react'
import { useDispatch } from 'react-redux';
import { libroDigitalTablaCalificacionesTaller, libroDigitalTablaClasesTaller } from '../../../action/router';
import { activarTaller } from '../../../action/taller';

export const RowTaller = ({taller, docentes, index}) => {
  const dispatch = useDispatch();
  const docentePrincipal = docentes.find(e=>e._id === taller.docente)

  const handleClases = () => {
    console.log('clases')
    dispatch(activarTaller(taller._id))
    dispatch(libroDigitalTablaClasesTaller())
  }


  const handleCalificaciones = () => {
    console.log('calificaicones')
    dispatch(activarTaller(taller._id))
    dispatch(libroDigitalTablaCalificacionesTaller())
  }

  return (
    <tr>
      <th>{index}</th>
      <td>{taller.nombre}</td>
      <td>{docentePrincipal.nombres} {docentePrincipal.apellidoPaterno} {docentePrincipal.apellidoMaterno}</td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-secondary btn-sm' onClick={handleClases} >Clases</button>
        </div>
      </td>
      <td>
        <div className='d-grid gap-2'>
          <button className='btn btn-secondary btn-sm' onClick={handleCalificaciones} >Calificaciones</button>
        </div>
      </td>
      <td>
        {taller.estado 
          ? 
            <div className='d-grid gap-2'>
              <button className='btn btn-success btn-sm' disabled >activo</button>
            </div>
          : 
            <div className='d-grid gap-2'>
              <button className='btn btn-danger btn-sm' disabled>inactivo</button>
            </div>
          }
      </td>
    </tr>
  )
}
