import React from 'react'
import { useDispatch } from 'react-redux';
import { activarPeriodoEscolar } from '../../../action/periodoEscolar';
import { libroDigitalTablaPeriodos } from '../../../action/router';
import { RowSinData } from '../../RowSinData'
import { RowTaller } from './RowTaller'

export const TablaTalleres = ({talleres, docentes}) => {
  const dispatch=useDispatch();

  const handleRegresar = () => {
    dispatch(activarPeriodoEscolar(''))
    dispatch(libroDigitalTablaPeriodos())
  }
  return (
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          <th>N°</th>
          <th>Nombre</th>
          <th>Docente</th>
          <th>Clases</th>
          <th>Calificaciones</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        {talleres
          ? talleres.map((e, index) => (
            <RowTaller 
              key={e._id}
              taller={e}
              docentes={docentes}
              index={index + 1}
            />
          ))
          :<RowSinData />
        }
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className='d-grid gap-2'>
              <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
