import React from 'react'

export const PromedioTaller = ({calificaciones}) => {
    console.log(calificaciones);
    let sumaNota = 0;
    for (let index = 0; index < calificaciones.length; index++) {
        const element = calificaciones[index];
        console.log(element)
        if(element){
            sumaNota = sumaNota + element.calificacion
        }
    }
    const promedio = sumaNota / calificaciones.length
  return (
    <div>{promedio.toFixed(1)}</div>
  )
}
