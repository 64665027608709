import React from 'react'
import { useDispatch } from 'react-redux';
import { activarAsistencia } from '../../../../action/asistencia';
import { libroDigitalAsistenciaRegistroScreen, libroDigitalTablaAsistencias } from '../../../../action/router';
import { RowAsistenciaEstudiante } from './RowAsistenciaEstudiante';
import { limpiarAsistenciaEstudiantes } from '../../../../action/asistenciaEstudiante';
import { formatFecha } from '../../../../helpers/formatData';

export const TablaAsistenciaEstudiantes = ({asistenciaActiva, asistencias, asistenciaEstudiantes, estudiantes}) => {
  const dispatch=useDispatch();
  const asistenciaPrincipal= asistencias.find(e=> e._id === asistenciaActiva);
  console.log("asistencias ---->", asistencias)
  console.log("asistenciaActiva ---->", asistenciaActiva)
  console.log("asistencia Principal",asistenciaPrincipal)
  const handleRegresar = () => {
    dispatch(activarAsistencia(''))
    dispatch(limpiarAsistenciaEstudiantes(''))
    dispatch(libroDigitalAsistenciaRegistroScreen())
  }
  return (
    <>
      <h4>Fecha: {formatFecha(asistenciaPrincipal.fecha)}</h4>
      <table className='table table-bordered table-hover'>
        <thead>
            <tr>
                <th>N°</th>
                <th>Estudiante</th>
                <th>Asistencia</th>
                <th>Observacion</th>
            </tr>
        </thead>
        <tbody>
            {estudiantes && estudiantes.map((e,index) => (
                <RowAsistenciaEstudiante
                    key={e._id}
                    estudiante={e}
                    asistenciaActiva={asistenciaActiva}
                    asistenciaEstudiantes={asistenciaEstudiantes}
                    index={index + 1}
                />

            ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className='d-grid gap-2'>
                <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  )
}
