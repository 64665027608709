import React, { useEffect } from 'react'
import { navigate } from "gatsby";
import { useDispatch, useSelector } from 'react-redux'
import { obtenerAniosEscolaresEstablecimiento } from '../../action/anioEscolar';
import { obtenerAsignaturasCursoPeriodoEscolar } from '../../action/asignatura';
import { obtenerCursosAnioEscolar } from '../../action/curso';
import { obtenerPeriodosEscolaresAnioEscolar } from '../../action/periodoEscolar';
import { obtenerDocentesEstablecimiento } from '../../action/usuario';
import { TablaAniosEscolares } from './anioEscolar/TablaAniosEscolares';
import { TablaAsignaturas } from './asignatura/TablaAsignaturas';
import { TablaCursos } from './curso/TablaCursos';
import { TablaPeriodos } from './periodoEscolar/TablaPeriodos';
import { TablaTalleres } from './taller/TablaTalleres';
import { obtenerTalleresCursoPeriodoEscolar } from '../../action/taller';
import { TablaClasesAsignatura } from './asignatura/clases/TablaClasesAsignatura';
import { obtenerClasesAsignatura } from '../../action/clasesAsignatura';
import { BotonClaseAsignaturaNueva } from './asignatura/clases/BotonClaseAsignaturaNueva';
import { FormClaseAsignaturaNueva } from './asignatura/clases/FormClaseAsignaturaNueva';
import { FormClaseAsignaturaActualizar } from './asignatura/clases/FormClaseAsignaturaActualizar';
import { TablaClasesTaller } from './taller/clases/TablaClasesTaller';
import { obtenerClasesTaller } from '../../action/clasesTaller';
import { FormClaseTallerNueva } from './taller/clases/FormClaseTallerNueva';
import { BotonClaseTallerNueva } from './taller/clases/BotonClaseTallerNueva';
import { FormAsistenciaNueva } from './curso/asistencia/FormAsistenciaNueva';
import { TablaAsistencias } from './curso/asistencia/TablaAsistencias';
import { obtenerAsistenciasCurso } from '../../action/asistencia';
import { obtenerEstudiantesCurso } from '../../action/estudiante';
import { TablaAsistenciaEstudiantes } from './curso/asistencia/TablaAsistenciaEstudiantes';
import { obtenerAsistenciaEstudiantesAsistencia } from '../../action/asistenciaEstudiante';
import { TablaEstudiantes } from './asignatura/calificaciones/TablaEstudiantes';
import { FormCalificacion } from './asignatura/calificaciones/FormCalificacion';
import { obtenerCalificacionesAsignatura } from '../../action/calificacionAsignatura';
import { obtenerCalificacionesDetalleAsignatura } from '../../action/calificacionDetalleAsignatura';
import { TablaEstudiantesCurso } from './curso/estudiantes/TablaEstudiantesCurso';
import { TablaAsignaturasCurso } from './curso/estudiantes/TablaAsignaturasCurso';
import { FormCalificacionTaller } from './taller/calificaciones/FormCalificacionTaller';
import { TablaEstudiantesTaller } from './taller/calificaciones/TablaEstudiantesTaller';
import { obtenerCalificacionesTaller } from '../../action/calificacionTaller';
import { obtenerCalificacionDetallesTaller } from '../../action/calificacionDetalleTaller';
import { CertificadoEstudiante } from './curso/estudiantes/certificadoCalificaciones/CertificadoEstudiante';
import { CertificadoEstudianteSegundo } from './curso/estudiantes/certificadoCalificacionesSegundo/CertificadoEstudianteSegundo';
import { AsistenciaScreen } from './moduloAsistencia/AsistenciaScreen';
import { AnotacionScreen } from './curso/anotaciones/AnotacionScreen';

export const LibroDigitalScreen = () => {
    const dispatch = useDispatch();
    const { credencialLogin, uid } = useSelector(state=> state.auth);
    const { credencialesUsuario, docentes } = useSelector(state=> state.usuario);
    const { estudiantes, estudianteActivo } = useSelector(state=> state.estudiante);
    const { 
        aniosEscolares, 
        anioEscolarActivo,
        cursos,
        cursoActivo,
        asistencias, 
        asistenciaActiva,
        asistenciaEstudiantes,
        asistenciaEstudianteActiva,
        periodosEscolares,
        periodoEscolarActivo,
        asignaturas,
        asignaturaActiva,
        clasesAsignatura,
        claseAsignaturaActiva,
        calificacionesAsignatura,
        talleres,
        tallerActivo,
        clasesTaller,
        claseTallerActiva,
        calificacionesTaller,
    } = useSelector(state=> state.libroDigital)
    const credencialUsuarioPrincipal = credencialesUsuario.find(e=> e._id === credencialLogin);

    const { 
        libroDigitalAsistenciaScreen,
        libroDigitalTablaAniosEscolares,
        libroDigitalTablaCursos,
        libroDigitalTablaEstudiantes,
        libroDigitalTablaAsignaturasCurso,
        libroDigitalTablaAsignaturasCursoSegundo,
        libroDigitalTablaAsistencias,
        libroDigitalTablaAsistenciaEstudiantes,
        libroDigitalAnotacionScreen,
        libroDigitalTablaPeriodos,
        libroDigitalTablaAsignaturas,
        libroDigitalTablaClasesAsignatura,
        libroDigitalFormClaseAsignaturaNueva,
        libroDigitalFormClaseAsignaturaActualizar,
        libroDigitalTablaCalificacionesAsignatura,
        libroDigitalTablaTalleres,
        libroDigitalTablaClasesTaller,
        libroDigitalFormClaseTallerNueva,
        libroDigitalFormClaseTallerActualizar,
        libroDigitalTablaCalificacionesTaller
     } = useSelector(state=> state.router);

    useEffect(() => {
        if(credencialUsuarioPrincipal){
            if(Object.entries(credencialUsuarioPrincipal).length>0){
                if(credencialUsuarioPrincipal.establecimiento !=='' && credencialUsuarioPrincipal.establecimiento !== null ){
                    const filtro = {
                        establecimiento: credencialUsuarioPrincipal.establecimiento
                    }
                    const obtenerAniosEscolaresPrincipal = () => dispatch(obtenerAniosEscolaresEstablecimiento(filtro));
                    const obtenerDocentesPrincipal = () => dispatch(obtenerDocentesEstablecimiento(filtro))
                    obtenerAniosEscolaresPrincipal()
                    obtenerDocentesPrincipal()
                }
            }
        }
        // eslint-disable-next-line
    }, [credencialUsuarioPrincipal]);

    useEffect(() => {
        if(anioEscolarActivo !=='' && anioEscolarActivo !== null ){
          const filtro = {
            anioEscolar: anioEscolarActivo
          }
          const obtenerCursosPrincipal = () => dispatch(obtenerCursosAnioEscolar(filtro));
          const obtenerPeriodosPrincipal = () => dispatch(obtenerPeriodosEscolaresAnioEscolar(filtro));
          obtenerCursosPrincipal()
          obtenerPeriodosPrincipal()
        }
        // eslint-disable-next-line
    }, [anioEscolarActivo]);


    useEffect(() => {
        if(cursoActivo !=='' && cursoActivo !== null && periodoEscolarActivo !=='' && periodoEscolarActivo !== null ){
          const filtro = {
            curso: cursoActivo,
            periodoEscolar: periodoEscolarActivo
          }
          const obtenerAsignaturasCursoPeriodoPricipal = () => dispatch(obtenerAsignaturasCursoPeriodoEscolar(filtro))
          const obtenerTalleresCursoPeriodoPricipal = () => dispatch(obtenerTalleresCursoPeriodoEscolar(filtro))
          obtenerAsignaturasCursoPeriodoPricipal()
          obtenerTalleresCursoPeriodoPricipal()
       
        }
        // eslint-disable-next-line
    }, [cursoActivo, periodoEscolarActivo]);

    useEffect(() => {
        if(cursoActivo !=='' && cursoActivo !== null ){
          const filtro = {
            curso: cursoActivo
          }
          const obtenerAsistenciasPrincipal = () => dispatch(obtenerAsistenciasCurso(filtro));
          const obtenerEstudiantesCursoPrincipal = () => dispatch(obtenerEstudiantesCurso(filtro));
          obtenerAsistenciasPrincipal()
          obtenerEstudiantesCursoPrincipal()
        }
        // eslint-disable-next-line
    }, [cursoActivo]);

    useEffect(() => {
        if(asignaturaActiva !=='' && asignaturaActiva !== null ){
          const filtro = {
            asignatura: asignaturaActiva
          }
          const obtenerClasesAsignaturaPrincipal = () => dispatch(obtenerClasesAsignatura(filtro));
          const obtenerCalificacionesAsignaturaPrincipal = () => dispatch(obtenerCalificacionesAsignatura(filtro));
          const obtenerCalificacionesDetalleAsignaturaPrincipal = () => dispatch(obtenerCalificacionesDetalleAsignatura(filtro));
          obtenerClasesAsignaturaPrincipal()
          obtenerCalificacionesAsignaturaPrincipal()
          obtenerCalificacionesDetalleAsignaturaPrincipal()
        }
        // eslint-disable-next-line
    }, [asignaturaActiva]);


    useEffect(() => {
        if(tallerActivo !=='' && tallerActivo !== null ){
            const filtro = {
                taller: tallerActivo
            }
            const obtenerClasesTallerPrincipal = () => dispatch(obtenerClasesTaller(filtro));
            const obtenerCalificacionesTallerPrincipal = () => dispatch(obtenerCalificacionesTaller(filtro));
            const obtenerCalificacionesDetalleTallerPrincipal = () => dispatch(obtenerCalificacionDetallesTaller(filtro));
            obtenerClasesTallerPrincipal()
            obtenerCalificacionesTallerPrincipal()
            obtenerCalificacionesDetalleTallerPrincipal()
        }
        // eslint-disable-next-line
    }, [tallerActivo]);

    useEffect(() => {
        if(asistenciaActiva !=='' && asistenciaActiva !== null ){
          const filtro = {
            asistencia: asistenciaActiva
          }
          const obtenerAsistenciaEstudiantesAsistenciaPrincipal = () => dispatch(obtenerAsistenciaEstudiantesAsistencia(filtro));
         
          obtenerAsistenciaEstudiantesAsistenciaPrincipal()
         
        }
        // eslint-disable-next-line
    }, [asistenciaActiva]);


    if(!uid){
        setTimeout(() => {
          navigate("/login/")
        }, 1500);
    }
    
    if(!credencialLogin){
        setTimeout(() => {
          navigate("/credencialUsuario/")
        }, 1500);
    }
    
    return (
        <div className='card'>
            <div className='card-body'>
                {/* <h3>Libro Digital</h3> */}
                <div className='row'>
                    {libroDigitalTablaAsistencias && 
                        <div className='col-12'>
                            <FormAsistenciaNueva 
                                cursoActivo={cursoActivo}
                            />
                        </div>
                    }
                    <div className='col-12'>
                        <div className='row'>
                            {libroDigitalTablaClasesAsignatura && 
                                <div className='col-3'>
                                    <BotonClaseAsignaturaNueva />
                                </div>
                            }
                            {libroDigitalTablaClasesTaller && 
                                <div className='col-3'>
                                    <BotonClaseTallerNueva />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-12'>
                        {libroDigitalTablaAniosEscolares && 
                            <TablaAniosEscolares 
                                aniosEscolares={aniosEscolares}
                            />
                        }
                        {libroDigitalTablaCursos && 
                            <TablaCursos 
                                cursos={cursos}
                                anioEscolarActivo={anioEscolarActivo}
                                aniosEscolares={aniosEscolares}
                            />
                        }
                        {/* {libroDigitalTablaAsistencias && 
                            <TablaAsistencias
                                asistencias={asistencias}
                                docentes={docentes}
                            />
                        } */}
                        { libroDigitalAsistenciaScreen && <AsistenciaScreen /> }
                        {/* {libroDigitalTablaAsistenciaEstudiantes && 
                            <TablaAsistenciaEstudiantes
                                estudiantes={estudiantes}
                                asistenciaActiva={asistenciaActiva}
                                asistencias={asistencias}
                                asistenciaEstudiantes={asistenciaEstudiantes}
                            />
                        } */}
                        {libroDigitalTablaEstudiantes && 
                            <TablaEstudiantesCurso
                                estudiantes={estudiantes}
                                asignaturas={asignaturas}
                                periodosEscolares={periodosEscolares}
                            />
                        }
                        {libroDigitalAnotacionScreen && 
                            <AnotacionScreen />
                        }
                        {libroDigitalTablaAsignaturasCurso && 
                            <CertificadoEstudiante />
                        }
                        {libroDigitalTablaAsignaturasCursoSegundo && 
                            <CertificadoEstudianteSegundo />
                        }



                        {libroDigitalTablaPeriodos &&
                            <TablaPeriodos 
                                periodosEscolares={periodosEscolares}
                            />
                        }
                        {libroDigitalTablaAsignaturas &&
                            <TablaAsignaturas 
                                asignaturas={asignaturas}
                                docentes={docentes}
                            />
                        }
                        {libroDigitalTablaClasesAsignatura && 
                            <TablaClasesAsignatura 
                                clasesAsignatura={clasesAsignatura}
                                docentes={docentes}
                                asignaturas={asignaturas}
                                asignaturaActiva={asignaturaActiva}
                            />
                        }
                        {libroDigitalFormClaseAsignaturaNueva && 
                            <FormClaseAsignaturaNueva 
                                asignaturaActiva={asignaturaActiva}
                            />
                        }
                        {libroDigitalFormClaseAsignaturaActualizar && 
                            <FormClaseAsignaturaActualizar 
                                claseAsignaturaActiva={claseAsignaturaActiva}
                                clasesAsignatura={clasesAsignatura}
                            />
                        }
                         {libroDigitalTablaCalificacionesAsignatura && 
                            <FormCalificacion
                                asignaturaActiva={asignaturaActiva}
                                cursoActivo={cursoActivo}
                            />
                        }
                        {libroDigitalTablaCalificacionesAsignatura && 
                            <TablaEstudiantes  
                                estudiantes={estudiantes}
                                calificacionesAsignatura={calificacionesAsignatura}
                                asignaturaActiva={asignaturaActiva}
                                asignaturas={asignaturas}
                            />
                        }

                        {libroDigitalTablaTalleres &&
                            <TablaTalleres 
                                talleres={talleres}
                                docentes={docentes}
                            />
                        }
                        {libroDigitalTablaClasesTaller && 
                            <TablaClasesTaller
                                clasesTaller={clasesTaller}
                                docentes={docentes}
                            />
                        }
                        {libroDigitalFormClaseTallerNueva && 
                            <FormClaseTallerNueva
                                tallerActivo={tallerActivo}
                            />
                        }
                        {libroDigitalFormClaseTallerActualizar && 
                            <FormClaseAsignaturaActualizar
                                claseTallerActiva={claseTallerActiva}
                                clasesTaller={clasesTaller}
                            />
                        }
                        {libroDigitalTablaCalificacionesTaller && 
                            <FormCalificacionTaller
                                tallerActivo={tallerActivo}
                                cursoActivo={cursoActivo}
                            />
                        }
                        {libroDigitalTablaCalificacionesTaller && 
                            <TablaEstudiantesTaller
                                estudiantes={estudiantes}
                                calificacionesTaller={calificacionesTaller}
                                tallerActivo={tallerActivo}
                                talleres={talleres}
                            />
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}
