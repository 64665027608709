import React from 'react'
import { RowAnioEscolar } from './RowAnioEscolar'

export const TablaAniosEscolares = ({aniosEscolares}) => {
  return (
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          <th>N°</th>
          <th>Año escolar</th>
          <th>Cursos</th>
        </tr>
      </thead>
      <tbody>
        {aniosEscolares && aniosEscolares.map((e, index)=>(
          <RowAnioEscolar 
            key={e._id}
            anioEscolar={e}
            index={index+1}
          />
        ))}
      </tbody>
    </table>
  )
}
