import React, { useEffect } from 'react'

import { FormAnotacion } from './FormAnotacion';
import { TablaAnotacionesEstudiante } from './TablaAnotacionesEstudiante';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerAnotaciones } from '../../../../action/anotacion';
import { libroDigitalTablaEstudiantes } from '../../../../action/router';
import { activarEstudiante } from '../../../../action/estudiante';

export const AnotacionScreen = () => {
    const dispatch = useDispatch()
    const {estudianteActivo, estudiantes} = useSelector(state=>state.estudiante);
    const estudiante = estudiantes.find(e => e._id === estudianteActivo)

    const handleRegresar = () => {
        dispatch(activarEstudiante(""))
        dispatch(libroDigitalTablaEstudiantes())
    }

    useEffect(() => {
        if(estudianteActivo !=='' ){
          const filtro = {
            estudiante: estudianteActivo,
          }
          const obtenerAnotacionesEstudiante = () => dispatch(obtenerAnotaciones(filtro));
          obtenerAnotacionesEstudiante()
        }
        // eslint-disable-next-line
    }, [estudianteActivo]);
    
    return (
        <div className="row">
            <div className="col-12">
                <h5>Estudiante: {estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno} </h5>
            </div>
            <div className="col-12">
                <FormAnotacion/>
            </div>
            <div className="col-12">
                <TablaAnotacionesEstudiante />
            </div>
            <div className="col-12">
                <button className="btn btn-danger" onClick={handleRegresar}>regresar</button>
            </div>
        </div>
    )
}
