import React from 'react'
import { useSelector } from 'react-redux'
import { formatFecha, getAnotacion } from '../../../../helpers/formatData';

export const RowAnotacion = ({anotacion}) => {
    const {asignaturas} = useSelector(state=> state.libroDigital);
    const asignatura = asignaturas.find(e=>e._id === anotacion.asignatura);
    const {docentes} = useSelector(state => state.usuario);
    const docente = docentes.find(e=>e._id === anotacion.creador);
    if(asignatura == undefined ){
        asignatura = {
            nombre:''
        };
    }

    return (
        <tr >
            <td>{formatFecha(anotacion.fecha)}</td>
            <td>{docente.nombres} {docente.apellidoPaterno}</td>
            <td>{getAnotacion(anotacion.tipo)}</td>
            <td>{asignatura.nombre}</td>
            <td>{anotacion.detalle}</td>
        </tr>
    )
}