import { fetchConToken, fetchConTokenFiltro, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearCalificacionDetalleAsignatura = ( calificacionDetalleAsignaturaAsignatura ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('libroDigital/crearAsignaturaCalificacionDetalle', calificacionDetalleAsignaturaAsignatura, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(crearCalificacionDetalleAsignaturaOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCalificacionDetalleAsignaturaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCalificacionDetalleAsignaturaError());
        }
    }
}

const crearCalificacionDetalleAsignaturaOk = (e) => ({
    type: types.crearCalificacionDetalleAsignaturaOk,
    payload: e
});

const crearCalificacionDetalleAsignaturaError = () => ({ type: types.crearCalificacionDetalleAsignaturaError });


export const obtenerCalificacionesDetalleAsignatura = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerCalificacionesDetallesAsignatura', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCalificacionesDetalleAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCalificacionesDetalleAsignaturaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCalificacionesDetalleAsignaturaError());
        }
    }
}

export const obtenerCalificacionesDetalleAsignaturaCurso = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConTokenFiltro('libroDigital/obtenerCalificacionesDetallesAsignaturasCurso', filtro);
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerCalificacionesDetalleAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerCalificacionesDetalleAsignaturaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCalificacionesDetalleAsignaturaError());
        }
    }
}


const obtenerCalificacionesDetalleAsignaturaOk = (e) => ({ 
    type: types.obtenerCalificacionesDetalleAsignaturaOk ,
    payload: e
});

const obtenerCalificacionesDetalleAsignaturaError = () => ({ type: types.obtenerCalificacionesDetalleAsignaturaError });



export const actualizarCalificacionDetalleAsignatura = (calificacionDetalle) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarAsignaturaCalificacionDetalle/${calificacionDetalle._id}`, calificacionDetalle, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionDetalleAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarCalificacionDetalleAsignaturaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarCalificacionDetalleAsignaturaError())
        }
    }
}

const actualizarCalificacionDetalleAsignaturaOk = (e) => ({ 
    type: types.actualizarCalificacionDetalleAsignaturaOk,
    payload: e
});

const actualizarCalificacionDetalleAsignaturaError = () => ({ type : types.actualizarCalificacionDetalleAsignaturaError });

export const activarCalificacionDetalleAsignatura = (e) => ({ 
    type: types.activarCalificacionDetalleAsignatura,
    payload: e
});

export const limpiarCalificacionesDetallesAsignaturaCalificacionDetalleAsignatura = () => ({ 
    type: types.limpiarCalificacionesDetalleAsignatura
});
