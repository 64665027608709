import React from 'react'
import { useSelector } from 'react-redux';

export const DetalleCalificacionAsignatura = ({calificacion, detallesCalificaciones}) => {
    const {calificacionesDetalleAsignatura} = useSelector(state => state.libroDigital);
    const detalleCalificacion = detallesCalificaciones.find(e => e.asignaturaCalificacion == calificacion._id);
    console.log(detalleCalificacion)
    if(!detalleCalificacion) return <td></td>
    return (
        <td>{detalleCalificacion.calificacion}</td>
    )
}
