import * as React from "react"
import Layout from '../components/layout'
import { LibroDigitalScreen } from "../components/libroDigital/LibroDigitalScreen"


// markup
const LibroDigital = () => {
  return (
    <Layout>
      <div className="container-xl">
        <LibroDigitalScreen />
      </div>
    </Layout>
  )
}

export default LibroDigital
