import React from 'react'
import { useDispatch } from 'react-redux'
import { activarEstudiante } from '../../../../action/estudiante'
import { libroDigitalAnotacionScreen, libroDigitalTablaAsignaturasCurso, libroDigitalTablaAsignaturasCursoSegundo } from '../../../../action/router'
import { activarPeriodoEscolar } from '../../../../action/periodoEscolar'

export const RowEstudiante = ({estudiante, cursoActivo, periodosEscolares, index}) => {
    const primerPeriodo = periodosEscolares.find(e => e.periodo === "Primer Semestre" )
    const segundoPeriodo = periodosEscolares.find(e => e.periodo === "Segundo Semestre" )
    const dispatch=useDispatch()
    const handleCalificaciones = () => {
        dispatch(activarEstudiante(estudiante._id))
        dispatch(activarPeriodoEscolar(primerPeriodo._id))
        dispatch(libroDigitalTablaAsignaturasCurso())
    }
    const handleCalificaciones2 = () => {
        dispatch(activarEstudiante(estudiante._id))
        dispatch(activarPeriodoEscolar(segundoPeriodo._id))
        dispatch(libroDigitalTablaAsignaturasCursoSegundo())
    }
    const handleAnotaciones = () => {
        dispatch(activarEstudiante(estudiante._id))
        dispatch(activarPeriodoEscolar(primerPeriodo._id))
        dispatch(libroDigitalAnotacionScreen())
    }
    
    return (
        <tr>
            <th>{index}</th>
            <td>{estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno}</td>
            
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-info btn-sm' onClick={handleAnotaciones}>Anotaciones</button>
                </div>
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-success btn-sm' onClick={handleCalificaciones} >Calificaciones</button>
                </div>
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-success btn-sm' onClick={handleCalificaciones2} >Calificaciones</button>
                </div>
            </td>
        </tr>
    )
}
