import React from 'react'
import { useDispatch } from 'react-redux'
import { FormCalificacionDetalle } from './FormCalificacionDetalle'
import { Promedio } from './Promedio'

export const RowEstudiantes = ({estudiante, calificacionesAsignatura, asignaturaActiva, index}) => {
    const dispatch = useDispatch()
    const { nombres, apellidoPaterno, apellidoMaterno } = estudiante
    return (
        <tr>
            <td>{index}</td>
            <td>{`${ nombres } ${apellidoPaterno} ${apellidoMaterno}`}</td>
            {calificacionesAsignatura.length > 0 && calificacionesAsignatura.map(e => (
                <td key={e._id}>
                    <FormCalificacionDetalle 
                        estudiante={estudiante}
                        calificacionActiva={e}
                        asignaturaActiva={asignaturaActiva}
                    />
                </td>
            ))}
            <td>
                <Promedio
                    estudiante={estudiante}
                    asignaturaActiva={asignaturaActiva}
                    calificacionesAsignatura={calificacionesAsignatura}
                />
            </td>
        </tr>
    )
}
