import { fetchConToken, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearClaseAsignatura = ( claseAsignatura ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('libroDigital/crearClaseAsignatura', claseAsignatura, 'POST');
            const body = await resp.json();
            
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearClaseAsignaturaOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearClaseAsignaturaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearClaseAsignaturaError());
        }
    }
}

const crearClaseAsignaturaOk = (e) => ({
    type: types.crearClaseAsignaturaOk,
    payload: e
});

const crearClaseAsignaturaError = () => ({ type: types.crearClaseAsignaturaError });


export const obtenerClasesAsignaturas = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerClasesAsignaturas');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerClasesAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerClasesAsignaturaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerClasesAsignaturaError());
        }
    }
}

export const obtenerClasesAsignatura = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerClasesAsignatura', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerClasesAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerClasesAsignaturaError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerClasesAsignaturaError());
        }
    }
}


const obtenerClasesAsignaturaOk = (e) => ({ 
    type: types.obtenerClasesAsignaturaOk ,
    payload: e
});

const obtenerClasesAsignaturaError = () => ({ type: types.obtenerClasesAsignaturaError });



export const actualizarClaseAsignatura = (claseAsignatura) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarClaseAsignatura/${claseAsignatura._id}`, claseAsignatura, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarClaseAsignaturaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarClaseAsignaturaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarClaseAsignaturaError())
        }
    }
}

const actualizarClaseAsignaturaOk = (e) => ({ 
    type: types.actualizarClaseAsignaturaOk,
    payload: e
});

const actualizarClaseAsignaturaError = () => ({ type : types.actualizarClaseAsignaturaError });

export const activarClaseAsignatura = (e) => ({ 
    type: types.activarClaseAsignatura,
    payload: e
});

export const limpiarClasesClaseAsignatura = () => ({ 
    type: types.limpiarClasesAsignatura
});
