import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearAnioEscolar = ( anioEscolar ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearAnioEscolar', anioEscolar, 'POST');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearAnioEscolarOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearAnioEscolarError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearAnioEscolarError());
        }
    }
}

const crearAnioEscolarOk = (e) => ({
    type: types.crearAnioEscolarOk,
    payload: e
});

const crearAnioEscolarError = () => ({ type: types.crearAnioEscolarError });


export const obtenerAniosEscolares = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerAniosEscolares');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAniosEscolaresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAniosEscolaresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAniosEscolaresError());
        }
    }
}

export const obtenerAniosEscolaresEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAniosEscolaresEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAniosEscolaresOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAniosEscolaresError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAniosEscolaresError());
        }
    }
}

const obtenerAniosEscolaresOk = (e) => ({ 
    type: types.obtenerAniosEscolaresOk ,
    payload: e
});

const obtenerAniosEscolaresError = () => ({ type: types.obtenerAniosEscolaresError });



export const actualizarAnioEscolar = (anioEscolar) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarAnioEscolar/${anioEscolar._id}`, anioEscolar, 'PUT');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAnioEscolarOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAnioEscolarError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarAnioEscolarError())
        }
    }
}

const actualizarAnioEscolarOk = (e) => ({ 
    type: types.actualizarAnioEscolarOk,
    payload: e
});

const actualizarAnioEscolarError = () => ({ type : types.actualizarAnioEscolarError });

export const activarAnioEscolar = (e) => ({ 
    type: types.activarAnioEscolar,
    payload: e
});

export const limpiarAniosEscolares = () => ({ 
    type: types.limpiarAniosEscolares
});
