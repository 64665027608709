import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearAsistenciaEstudiante = ( asistenciaEstudiante ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearAsistenciaEstudiante', asistenciaEstudiante, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearAsistenciaEstudianteOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearAsistenciaEstudianteError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearAsistenciaEstudianteError());
        }
    }
}

const crearAsistenciaEstudianteOk = (e) => ({
    type: types.crearAsistenciaEstudianteOk,
    payload: e
});

const crearAsistenciaEstudianteError = () => ({ type: types.crearAsistenciaEstudianteError });


export const obtenerAsistenciaEstudiantes = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerAsistenciaEstudiantes');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsistenciaEstudiantesOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsistenciaEstudiantesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsistenciaEstudiantesError());
        }
    }
}

export const obtenerAsistenciaEstudiantesCurso = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsistenciaEstudiantesCurso', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsistenciaEstudiantesOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsistenciaEstudiantesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsistenciaEstudiantesError());
        }
    }
}
export const obtenerAsistenciaEstudiantesAsistencia = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsistenciaEstudiantesAsistencia', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsistenciaEstudiantesOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsistenciaEstudiantesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsistenciaEstudiantesError());
        }
    }
}

export const obtenerAsistenciaEstudiantesAnioEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerAsistenciaEstudiantesAnioEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerAsistenciaEstudiantesOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerAsistenciaEstudiantesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerAsistenciaEstudiantesError());
        }
    }
}

const obtenerAsistenciaEstudiantesOk = (e) => ({ 
    type: types.obtenerAsistenciaEstudiantesOk ,
    payload: e
});

const obtenerAsistenciaEstudiantesError = () => ({ type: types.obtenerAsistenciaEstudiantesError });



export const actualizarAsistenciaEstudiante = (asistenciaEstudiante) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarAsistenciaEstudiante/${asistenciaEstudiante._id}`, asistenciaEstudiante, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                dispatch(actualizarAsistenciaEstudianteOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarAsistenciaEstudianteError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarAsistenciaEstudianteError())
        }
    }
}

const actualizarAsistenciaEstudianteOk = (e) => ({ 
    type: types.actualizarAsistenciaEstudianteOk,
    payload: e
});

const actualizarAsistenciaEstudianteError = () => ({ type : types.actualizarAsistenciaEstudianteError });

export const activarAsistenciaEstudiante = (e) => ({ 
    type: types.activarAsistenciaEstudiante,
    payload: e
});

export const limpiarAsistenciaEstudiantes = () => ({ 
    type: types.limpiarAsistenciaEstudiantes
});
