import React from 'react'
import { useSelector } from 'react-redux'
import { RowAnotacion } from './RowAnotacion';

export const TablaAnotacionesEstudiante = () => {
    const {anotaciones} = useSelector(state => state.estudiante);
    return (
        <table className="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Docente</th>
                    <th>Tipo</th>
                    <th>Asignatura</th>
                    <th>Detalle</th>
                </tr>
            </thead>
            <tbody>
                {anotaciones && anotaciones.map(e => (
                    <RowAnotacion
                        key={e._id} 
                        anotacion={e}
                    />
                ))}
            </tbody>
        </table>
    )
}
