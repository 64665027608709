import React from 'react'
import { useDispatch } from 'react-redux';
import { activarCurso } from '../../../action/curso';
import { libroDigitalTablaCursos } from '../../../action/router';
import { RowPeriodo } from './RowPeriodo'

export const TablaPeriodos = ({periodosEscolares}) => {
    const dispatch=useDispatch();

    const handleRegresar = () => {
        dispatch(activarCurso(''))
        dispatch(libroDigitalTablaCursos())
    }
    return (
      <table className='table table-bordered table-hover'>
            <thead>
                <tr>
                    <th>N°</th>
                    <th>Nombre</th>
                    <th>Asignaturas</th>
                    <th>Talleres</th>
                </tr>
            </thead>
            <tbody>
                {periodosEscolares && periodosEscolares.map((e,index) => (
                    <RowPeriodo
                        key={e._id}
                        periodoEscolar={e}
                        index={index + 1}
                    />

                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}
