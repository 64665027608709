import React from 'react'
import { useForm } from '../../../../hooks/useForm';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { actualizarCalificacionDetalleTaller } from '../../../../action/calificacionDetalleTaller';

export const FormCalificacionDetalleTallerActualizar = ({calificacionDetalleTallerActivo}) => {
    const dispatch = useDispatch();
    const [calificacionDetalleTaller, handleInputChange, reset] = useForm(calificacionDetalleTallerActivo)

    const  { calificacion } = calificacionDetalleTaller;

    const handleEnviar = (e) => {
        e.preventDefault()
        if( calificacion === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        
        if( calificacion < 2 || calificacion > 7){
            return Swal.fire({
                icon: 'warning',
                title: 'La calificacion debe ser entre 2 y 7',
                showConfirmButton: false,
                timer: 1000
            });
        } 
        calificacionDetalleTaller.calificacion = calificacion.replace(',', '.')
        dispatch(actualizarCalificacionDetalleTaller(calificacionDetalleTaller));
    }
    return (
        <form>
            <div className="row mb-3 mt-3">
                <div className="col-9">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="calificacion"
                        name="calificacion"
                        value={ calificacion }
                        onChange={ handleInputChange }
                    />
                </div>
                
            
                <div className="col-3">
                    <button 
                        type="submit" 
                        className="btn btn-primary btn-small" 
                        onClick={ handleEnviar }
                    >M</button>
                </div>
            </div>
        </form>
    )
}
